import React, { useState, useEffect } from 'react';
import { AppRouter } from './AppRouter';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

// import { ipcRenderer } from 'electron';

// import './printStyle.css';
import { configureStore } from './store';
export const storeConfiguration = configureStore();
const store = storeConfiguration;
// const { store, persistor } = storeConfiguration;

function App() {
  return (
    <Provider store={store}>
      {/* <PersistGate loading={null} persistor={persistor}> */}
      <AppRouter />
      {/* </PersistGate> */}
    </Provider>
  );
}

export default App;
