import uiActions from '../actions/types/ui';

const initialState = {
  currentPage: 'Home',
};

export default function(state = initialState, action) {
  console.log('action', action);

  switch (action.type) {
    case uiActions.PAGE_SELECTION:
      return {
        ...state,
        currentPage: action.payload.value,
      };
    default:
      return state;
  }
}
