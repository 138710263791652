import _get from "lodash.get";
import microBiologyConfig from "reportConfig/microbiology";

const Body = ({ reportData }) => {
  const reportList = Object.values(reportData) || [];

  return reportList.length > 0 ? (
    <>
      {reportList.map((report) => {
        if (report.key === microBiologyConfig.key) {
          return getMicrobiologyReportView(report);
        }
        return getIndividualReport(report);
      })}
    </>
  ) : (
    <>
      Seems like you havenot added any report data. Goto report page to add
      some.
    </>
  );
};

const getIndividualReport = (report) => {
  const renderedParents = [];
  const dataExists = doesDataExists(report);
  if (!dataExists) return <></>;

  return (
    <div key={report.key}>
      <div id="biochemicalReport">
        <div className="report-on">
          <label>Report On : {report.reportView.title}</label>
          {report.reportView.subTitle ? (
            <>
              <br />
              <br />
              <label>{report.reportView.subTitle}</label>
            </>
          ) : null}
        </div>
        <table className={report.key}>
          <tr>
            {report.reportView.tableHeaders.map((header) => (
              <th className={header.className}>{header.label}</th>
            ))}
          </tr>
          {report.reports
            .filter((report) => _get(report, "value", "") !== "")
            .map((testReport, index) => {
              console.log("testReport", testReport);
              // if (testReport.value != null) {
              if (testReport.rowClass !== "child") {
                return (
                  <tr>
                    {/* <td className="sn">{index}</td> */}
                    <td className={`test ${testReport.rowClass}`}>
                      {testReport.test}
                    </td>
                    <td className="input">
                      <span className="sm">{testReport.value}</span>
                    </td>
                    <td className="unit">
                      <span>{testReport.unit}</span>
                    </td>
                    <td className="reference">
                      {testReport.reference.map((reference) => (
                        <div>{reference}</div>
                      ))}
                    </td>
                  </tr>
                );
              }
              // rowClass === 'child'
              if (!renderedParents.includes(testReport.parentKey)) {
                const parentRow = report.reports.find(
                  (row) => row.key === testReport.parentKey
                );
                renderedParents.push(parentRow.key);
                console.log("renderedParents", renderedParents);
                // console.log('parentRow', parentRow);
                return (
                  <>
                    <tr>
                      <td className={`test ${parentRow.rowClass}`}>
                        {parentRow.test}
                      </td>
                    </tr>
                    <tr>
                      <td className={`test ${testReport.rowClass}`}>
                        {testReport.test}
                      </td>
                      <td className="input">
                        <span className="sm">{testReport.value}</span>
                      </td>
                      <td className="unit">
                        <span>{testReport.unit}</span>
                      </td>
                      <td className="reference">
                        {testReport.reference.map((reference) => (
                          <div>{reference}</div>
                        ))}
                      </td>
                    </tr>
                  </>
                );
              }
              return (
                <tr>
                  <td className={`test ${testReport.rowClass}`}>
                    {testReport.test}
                  </td>
                  <td className="input">
                    <span className="sm">{testReport.value}</span>
                  </td>
                  <td className="unit">
                    <span>{testReport.unit}</span>
                  </td>
                  <td className="reference">
                    {testReport.reference.map((reference) => (
                      <div>{reference}</div>
                    ))}
                  </td>
                </tr>
              );

              // }
            })}
        </table>
      </div>
    </div>
  );
};

const doesDataExists = (report) => {
  const dataExistence = _get(report, "reports", []).reduce((final, item) => {
    const valueExists = !!item["value"];
    return final || valueExists;
  }, false);

  return dataExistence;
};

const getMicrobiologyReportView = (mainReport) => {
  let report = [];
  switch (mainReport.testData.selectedTestType) {
    case microBiologyConfig.testType.stain.key:
      report = mainReport.stainConfig;
      const dataExists = doesDataExists(report);
      if (!dataExists) return null;
      return (
        <div key={report.key} id={mainReport.key}>
          <div className="report-on">
            <label>Report On : {report.reportView.title}</label>
            {report.reportView.subTitle ? (
              <>
                <br />
                <br />
                <label>{report.reportView.subTitle}</label>
              </>
            ) : null}
          </div>
          <div className="microbiologyDescription">
            <table className="topTable">
              <tr>
                <td>
                  <label>Specimen: </label>
                </td>
                <td>
                  <span className="font-l">{mainReport.testData.specimen}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <label>Test: </label>
                </td>
                <td>
                  <span className="font-l">{mainReport.testData.test}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <label>Test Result: </label>
                </td>
                <td>
                  <span className="strong font-l">
                    {mainReport.testData.testResult}
                  </span>
                </td>
              </tr>
            </table>
            <table className={mainReport.key}>
              {report.reports
                .filter((report) => _get(report, "value", ""))
                .map((testReport) => {
                  return (
                    <tr>
                      <td className="testDayTD">{testReport.label}</td>
                      <td className="resultTD">
                        <span className="microbioResult">
                          {testReport.value}
                        </span>
                      </td>
                    </tr>
                  );
                })}
            </table>
          </div>
        </div>
      );
    case microBiologyConfig.testType.culture.key:
      return <CultureReport cultureResult={mainReport} />;

    default:
      report = [];
  }
};

const CultureReport = (props) => {
  const report = props.cultureResult;

  return (
    <div key={report.key} id={report.key}>
      <div className="report-on">
        <label>Report On : {report.cultureConfig.reportView.title}</label>
        {report.cultureConfig.reportView.subTitle ? (
          <>
            <br />
            <br />
            <label>{report.cultureConfig.reportView.subTitle}</label>
          </>
        ) : null}
      </div>
      <div className="microbiologyDescription">
        <table className="topTable">
          <tr>
            <td>
              <label>Specimen: </label>
            </td>
            <td>
              <span className="font-l">{report.testData.specimen}</span>
            </td>
          </tr>
          <tr>
            <td>
              <label>Test: </label>
            </td>
            <td>
              <span className="font-l">{report.testData.test}</span>
            </td>
          </tr>
          <tr>
            <td>
              <label>Test Result: </label>
            </td>
            <td>
              <span className="strong font-l">
                {report.testData.testResult}
              </span>
            </td>
          </tr>
        </table>
      </div>

      <div className="cultureTableDiv">
        <div className="positive culture">
          <label className="strong">Sensitive</label>
          <table>
            {report.cultureConfig.sensitive.length === 0 ? (
              <tr>
                <td>
                  <label>
                    <strong>-</strong>
                  </label>
                </td>
              </tr>
            ) : (
              report.cultureConfig.sensitive.map((sensitiveMed, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <label>
                        <strong>{index + 1}</strong>
                      </label>
                    </td>
                    <td>
                      <div className="ui-widget">
                        <span className="tags">{sensitiveMed.content}</span>
                      </div>
                    </td>
                  </tr>
                );
              })
            )}
          </table>
        </div>
        <div className="negative culture">
          <label className="strong" style={{ display: "inline-block" }}>
            Resistant
          </label>
          <table>
            {report.cultureConfig.resistant.length === 0 ? (
              <tr>
                <td>
                  <label>
                    <strong>-</strong>
                  </label>
                </td>
              </tr>
            ) : (
              report.cultureConfig.resistant.map((resistantMed, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <label>
                        <strong>{index + 1}</strong>
                      </label>
                    </td>
                    <td>
                      <div className="ui-widget">
                        <span className="tags">{resistantMed.content}</span>
                      </div>
                    </td>
                  </tr>
                );
              })
            )}
          </table>
        </div>
      </div>
    </div>
  );
};

export default Body;
