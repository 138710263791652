import React from "react";
import _get from "lodash.get";
import { connect } from "react-redux";
import { Row, Col, Table, Form, Input } from "antd";
import { useContext, useState, useEffect, useRef } from "react";

import { capitalizeFirstLetter } from "utils/string";
import urineAnalysisConfig from "reportConfig/urineAnalysis";

class UrineAnalysis extends React.Component {
  constructor(props) {
    super(props);

    this.columns = urineAnalysisConfig.viewConfig;
  }

  componentDidMount() {
    this.props.initReport();
  }

  render() {
    return <>{this._getTable(this.props.report.reports)}</>;
  }

  _getHeader = (config) => {
    return (
      <Row gutter={16}>
        {config.map((viewType, index) => (
          <Col className="gutter-row" span={6} key={index}>
            <strong>{capitalizeFirstLetter(viewType)}</strong>
          </Col>
        ))}
      </Row>
    );
  };

  handleSave = (row) => {
    this.props.updateTestResult(row);
  };

  _getTable = (reports) => {
    const components = {
      body: {
        cell: EditableCell,
        row: EditableFormRow,
      },
    };

    const columns = this.columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: _get(record, "show", true),
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
        }),
      };
    });

    return (
      <Table
        components={components}
        bordered
        pagination={false}
        dataSource={reports}
        columns={columns}
        rowClassName="editable-row"
        // onRow={(record, index) => {
        // let style = record.testkoClas === 'child' ? { color: 'blue', marginLeft: 10 } : { margin: 0 };
        // return { style: style };
        // }}
      />
    );
  };
}

const mapStateToProps = (state) => {
  return { report: _get(state, "data.report.urineAnalysis", {}) };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initReport: () =>
      dispatch({ type: "REPORT_INIT", payload: urineAnalysisConfig }),
    updateTestResult: (data) =>
      dispatch({
        type: "REPORT_UPDATE_RESULT",
        payload: { test: data, reportType: "urineAnalysis" },
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UrineAnalysis);

const EditableContext = React.createContext();

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();

  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableFormRow = EditableRow;

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  // console.log("record", record);
  const [editing, setEditing] = useState(true);
  const inputRef = useRef();
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      // inputRef.current.focus();
    }
  }, [editing]);

  // const toggleEdit = () => {
  //   setEditing(!editing);
  //   form.setFieldsValue({
  //     [dataIndex]: record[dataIndex],
  //   });
  // };

  const save = async (e) => {
    try {
      // console.log("values", form);
      const values = await form.validateFields();
      // toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      // console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: false,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input
          ref={inputRef}
          onPressEnter={save}
          defaultValue={""}
          onBlur={save}
        />
      </Form.Item>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};
