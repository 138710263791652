import React, { useEffect, useState } from 'react';
import _get from 'lodash.get';
import { connect } from 'react-redux';
import { Layout, Breadcrumb } from 'antd';
import { useNavigate } from 'react-router-dom';

import SideMenu from '../components/sideMenu';
import { pageConstants } from 'constants/page';

const { Content, Header, Footer } = Layout;

export function withMainWrapper(WrappedComponent, selectedPage) {
  function Main(props) {
    let [sideMenuCollapsed, setSideMenuCollapsed] = useState(true);

    let navigate = useNavigate();

    useEffect(() => {
      console.log('selectedPage', selectedPage);
      props._selectPage(selectedPage);
    }, []);

    const _selectPage = (e) => {
      props._selectPage(e);
      navigate(`/${e}`);
    };

    const _getPageTitle = (selectedPageKey) => {
      return _get(pageConstants, `[${selectedPageKey}].title`, pageConstants.home.title);
    };

    const currentPage = _get(props, 'ui.currentPage', pageConstants.home.key);
    return (
      <Layout style={{ maxHeight: '100%' }} hasSider={true}>
        {/* <Header /> */}
        <SideMenu
          sideMenuCollapsed={sideMenuCollapsed}
          onCollapse={setSideMenuCollapsed}
          selectPage={_selectPage}
          currentPage={currentPage}
          default
        />
        <Layout>
          <Content>
            <Header style={{ paddingLeft: '10px', color: '#fff' }}>
              <span>
                <strong>{_getPageTitle(currentPage)}</strong>
                {/* <Breadcrumb>
                <Breadcrumb.Item>{this.props.ui.currentPage}</Breadcrumb.Item>
              </Breadcrumb> */}
              </span>
            </Header>
            <div
              style={{
                margin: 16,
                borderRadius: '5px',
                padding: 16,
                background: '#fff',
                minHeight: window.innerHeight - 32 - 64 - 68, // 40=> margin,64=> header height, 68=> footer height
                // marginBottom: '100px',
              }}
            >
              <WrappedComponent sideMenuCollapsed={sideMenuCollapsed} {...props} />
            </div>
            <Footer
              style={{
                textAlign: 'center',
                // position: 'fixed',
                // bottom: '0',
                width: '100%',
              }}
            >
              Kugel Design ©2019
            </Footer>
          </Content>
        </Layout>
      </Layout>
    );
  }
  class Main2 extends React.Component {
    // let navigate = useNavigate();
    state = {
      sideMenuCollapsed: true,
    };

    componentDidMount() {
      this.props._selectPage(selectedPage);
    }

    onCollapse = (sideMenuCollapsed) => {
      this.setState({ sideMenuCollapsed });
    };

    _selectPage = (e) => {
      this.props._selectPage(e);
      // navigate(`/${e}`);
    };

    render() {
      const currentPage = _get(this.props, 'ui.currentPage', pageConstants.home.key);

      return (
        <Layout style={{ maxHeight: '100%' }} hasSider={true}>
          {/* <Header /> */}
          <SideMenu
            sideMenuCollapsed={this.state.sideMenuCollapsed}
            onCollapse={this.onCollapse}
            selectPage={this._selectPage}
            currentPage={currentPage}
            default
          />
          <Layout>
            <Content>
              <Header style={{ paddingLeft: '10px', color: '#fff' }}>
                <span>
                  <strong>{this._getPageTitle(currentPage)}</strong>
                  {/* <Breadcrumb>
                <Breadcrumb.Item>{this.props.ui.currentPage}</Breadcrumb.Item>
              </Breadcrumb> */}
                </span>
              </Header>
              <div
                style={{
                  margin: 16,
                  borderRadius: '5px',
                  padding: 16,
                  background: '#fff',
                  minHeight: window.innerHeight - 32 - 64 - 68, // 40=> margin,64=> header height, 68=> footer height
                  // marginBottom: '100px',
                }}
              >
                <WrappedComponent {...this.state} {...this.props} />
              </div>
              <Footer
                style={{
                  textAlign: 'center',
                  // position: 'fixed',
                  // bottom: '0',
                  width: '100%',
                }}
              >
                Kugel Design ©2019
              </Footer>
            </Content>
          </Layout>
        </Layout>
      );
    }

    _getPageTitle = (selectedPageKey) => {
      return _get(pageConstants, `[${selectedPageKey}].title`, pageConstants.home.title);
    };
  }

  const mapStateToProps = (state) => {
    // console.log('state from props', state);

    return {
      ui: state.ui,
    };
  };

  const mapDispatchToProps = (dispatch) => ({
    _selectPage: (value) => {
      // console.log("value", value);
      return dispatch({ type: 'PAGE_SELECTION', payload: { value: value } });
    },
  });

  return connect(mapStateToProps, mapDispatchToProps)(Main);
}
