import dataActions from "./types/data";
import reportActions from "./types/report";

export function updateData(data) {
  return {
    type: dataActions.UPDATE_DATA,
    payload: data,
  };
}

export function reset() {
  return {
    type: dataActions.RESET_DATA,
    // payload: {},
  };
}

export function removeReportActions(reportKey) {
  return {
    type: reportActions.REPORT_REMOVE,
    payload: { removeKey: reportKey },
  };
}

export function resetAllReport() {
  return {
    type: reportActions.REPORT_RESET_DATA,
  };
}
