import React from "react";
import { connect } from "react-redux";
import ReactToPrint from "react-to-print";
import { Row, Col, Button, Card, Modal } from "antd";

import Header from "./generator/Header";
import Body from "./generator/Body/Body";

import * as userActions from "../../../actions/user";
import { resetAllReport } from "../../../actions/data";

import "./report.css";

const CLINIC_INFO = {
  registrationID: "14030/695/070/071",
  labName: "THECHO MEDICAL PATHOLOGICAL LAB",
  labAddress: "Thecho-9, Lalitpur",
  labAddressHelper: "(Way to Dhapakhel Dobato)",
  labContactInfo: "Phone no.: 01-5571204 , 9841699953",
};
class Report extends React.Component {
  state = {
    pageSize: "a4",
  };

  render() {
    return (
      <div className={`page ${this.state.pageSize}`}>
        <Header user={this.props.user} clinicInfo={CLINIC_INFO} />
        <Body reportData={this.props.reportData} />
      </div>
    );
  }
}

class ReportPreview extends React.Component {
  state = {
    showResetModal: false,
  };

  render() {
    return (
      <div className="page-container">
        <Modal
          title="Reset data?"
          visible={this.state.showResetModal}
          onOk={() => {
            this.props._resetAllReport();
            this.setState({ showResetModal: false });
            this.props._onSubmitPrint();
          }}
          onCancel={() => this.setState({ showResetModal: false })}
          // okButtonProps={{
          //   disabled: false,
          //   htmlType: "submit",
          //   onClick: this._onReportSelected,
          // }}
          cancelButtonProps={{ disabled: false }}
          destroyOnClose={false}
          maskClosable={false}
          mask={true}
        >
          <p>Remove all data?</p>
        </Modal>
        <div className="page-border">
          <Report {...this.props} ref={(el) => (this.componentRef = el)} />
        </div>
        <Card>
          <Row>
            <Col span={6} offset={18}>
              <ReactToPrint
                onAfterPrint={() => {
                  this.setState({ showResetModal: true });
                }}
                trigger={() => {
                  // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                  // to the root node of the returned component as it will be overwritten.
                  return (
                    <Button
                      block={true}
                      htmlType={"submit"}
                      size={"large"}
                      type={"primary"}
                      disabled={Object.keys(this.props.reportData).length === 0}
                      onClick={this.props.onSubmit}
                    >
                      Print
                    </Button>
                  );
                }}
                content={() => this.componentRef}
              />
            </Col>
          </Row>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.data.user,
    reportData: state.data.report,
  };
};

const mapDispatchToProps = (dispatch) => ({
  _resetAllReport: () => {
    dispatch(userActions.resetUser());
    dispatch(resetAllReport());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportPreview);
