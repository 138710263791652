import React from 'react';

import { pageConstants } from 'constants/page';
import { withMainWrapper } from 'hoc/withMainWrapper';

class Home extends React.Component {
  render() {
    return <div>This is home.</div>;
  }
}

export default withMainWrapper(Home, pageConstants.home.key);
