const serologyConfig = {
  reports: [
    {
      test: "HIV I & II",
      value: "",
      unit: "",
      reference: [""],
      rowClass: "parent",
      key: "hIVIII",
    },
    {
      test: "Malarial Antigen",
      value: "",
      unit: "",
      reference: [""],
      rowClass: "parent",
      key: "malarialAntigen",
    },
    {
      test: "HBsAg",
      value: "",
      unit: "",
      reference: [""],
      rowClass: "parent",
      key: "hBsAg",
    },
    {
      test: "ANTI-HCV",
      value: "",
      unit: "",
      reference: [""],
      rowClass: "parent",
      key: "aNTIHCV",
    },
    {
      test: "C-Reactive Protein",
      value: "",
      unit: "",
      reference: [""],
      rowClass: "parent",
      key: "cReactiveProtein",
    },
    {
      test: "VDRL/RPR",
      value: "",
      unit: "",
      reference: [""],
      rowClass: "parent",
      key: "vDRLRPR",
    },
    {
      test: "Rheumatiod Factor",
      value: "",
      unit: "",
      reference: [""],
      rowClass: "parent",
      key: "rheumatiodFactor",
    },
    {
      test: "A.N.A. (A.N.F)",
      value: "",
      unit: " ",
      reference: [""],
      rowClass: "parent",
      key: "aNAANF",
    },
    {
      test: "Anti-NN.A.",
      value: "",
      unit: "",
      reference: [""],
      rowClass: "parent",
      key: "antiNNA",
    },
    {
      test: "ASO",
      value: "",
      unit: "",
      reference: [""],
      rowClass: "parent",
      key: "aSO",
    },
    {
      test: "Aldehyde Test",
      value: "",
      unit: "",
      reference: [""],
      rowClass: "parent",
      show: false,
      key: "aldehydeTest",
    },
    {
      test: "Salm- Agglu [Widel Test]",
      value: "",
      unit: "",
      reference: [""],
      rowClass: "parent semichild",
      show: "0",
      key: "salmAggluWidelTest",
    },
    {
      test: "Sal. Typhi (O)",
      value: "",
      unit: "",
      reference: [""],
      rowClass: "child",
      key: "salTyphiO",
      parentKey: "aldehydeTest",
    },
    {
      test: "Sal. Typhi (H)",
      value: "",
      unit: "",
      reference: [""],
      rowClass: "child",
      key: "salTyphiH",
      parentKey: "aldehydeTest",
    },
    {
      test: "Sal. Para Typhi (AH)",
      value: "",
      unit: "",
      reference: [""],
      rowClass: "child",
      key: "salParaTyphiAH",
      parentKey: "aldehydeTest",
    },
    {
      test: "Sal. Para Typhi (BH)",
      value: "",
      unit: "",
      reference: [""],
      rowClass: "child",
      key: "salParaTyphiBH",
      parentKey: "aldehydeTest",
    },
    {
      test: "",
      value: "",
      unit: "",
      reference: [""],
      rowClass: "parent",
      key: "",
    },
  ],
  key: "serology",
  tabTitle: "Serology",
  viewConfig: [
    {
      title: "Test name",
      dataIndex: "test",
      width: "25%",
      editable: false,
    },
    {
      title: "Result",
      dataIndex: "value",
      width: "15%",
      editable: true,
    },
  ],
  reportView: {
    title: "Serology",
    tableHeaders: [
      //   { label: 'S.N', className: 'sn' },
      { label: "Test", className: "test" },
      { label: "Result", className: "input" },
      //   { label: 'Unit', className: 'unit' },
      //   { label: 'Normal Value', className: 'unit' },
    ],
  },
};

export default serologyConfig;
