const biochemistryConfig = {
  reports: [
    {
      test: "Blood Sugar Fasting",
      value: "",
      unit: "mg %",
      reference: ["60-120"],
      rowClass: "parent",
      key: "bloodSugarFasting",
    },
    {
      test: "Blood Sugar P.P",
      value: "",
      unit: "mg %",
      reference: ["90-150"],
      rowClass: "parent",
      key: "bloodSugarPP",
    },
    {
      test: "Blood Sugar Random",
      value: "",
      unit: "mg %",
      reference: ["60-150"],
      rowClass: "parent",
      key: "bloodSugarRandom",
    },
    {
      test: "Blood Sugar 1hr P.P.",
      value: "",
      unit: "mg %",
      reference: ["90-150"],
      rowClass: "parent",
      key: "bloodSugar1hrPP",
    },
    {
      test: "Blood Sugar pre-dinner",
      value: "",
      unit: "mg %",
      reference: ["90-150"],
      rowClass: "parent",
      key: "bloodSugarpredinner",
    },
    {
      test: "Blood Sugar post-dinner 1hr",
      value: "",
      unit: "mg %",
      reference: ["90-150"],
      rowClass: "parent",
      key: "bloodSugarpostdinner1hr",
    },
    {
      test: "Blood Sugar post-dinner 2hrs",
      value: "",
      unit: "mg %",
      reference: ["90-150"],
      rowClass: "parent",
      key: "bloodSugarpostdinner2hrs",
    },
    {
      test: "Blood Sugar",
      value: "",
      unit: "",
      reference: [""],
      rowClass: "parent",
      key: "bloodSugar",
    },
    {
      test: "Blood Urea",
      value: "",
      unit: "mg %",
      reference: ["15-45"],
      rowClass: "parent",
      key: "bloodUrea",
    },
    {
      test: "S. Creatinine",
      value: "",
      unit: "mg %",
      reference: ["Male: 0.7-1.3", "Female: 0.6-1.1"],
      rowClass: "parent",
      key: "sCreatinine",
    },
    {
      test: "S. Electrolytes",
      value: "",
      unit: "",
      reference: [""],
      rowClass: "parent",
      key: "sElectrolytes",
    },
    {
      test: "Sodium",
      value: "",
      unit: "mmol/L",
      reference: ["135-155"],
      rowClass: "child",
      key: "sodium",
      parentKey: "sElectrolytes",
    },
    {
      test: "Potassium",
      value: "",
      unit: "mmol/L",
      reference: ["3.5-5.5"],
      rowClass: "child",
      key: "potassium",
      parentKey: "sElectrolytes",
    },
    {
      test: "S. Uric Acid",
      value: "",
      unit: "mg %",
      reference: ["Male: 3.4-7.0", "Female: 2.4-6.0"],
      rowClass: "parent",
      key: "sUricAcid",
    },
    {
      test: "S. Cholesterol",
      value: "",
      unit: "mg %",
      reference: ["150-250"],
      rowClass: "parent",
      key: "sCholesterol",
    },
    {
      test: "S. Triglyceridges",
      value: "",
      unit: "mg %",
      reference: ["65-200"],
      rowClass: "parent",
      key: "sTriglyceridges",
    },
    {
      test: "S.H.D.L",
      value: "",
      unit: "mg %",
      reference: ["Male: 35-55", " Female: 45-60"],
      rowClass: "parent",
      key: "sHDL",
    },
    {
      test: "S.L.D.L",
      value: "",
      unit: "mg %",
      reference: ["Less than 150"],
      rowClass: "parent",
      key: "sLDL",
    },
    {
      test: "S. Bilirubin total",
      value: "",
      unit: "mg %",
      reference: ["0.4-1.0"],
      rowClass: "parent",
      key: "sBilirubintotal",
    },
    {
      test: "S. Bilirubin Direct",
      value: "",
      unit: "mg %",
      reference: ["0.0-0.4"],
      rowClass: "parent",
      key: "sBilirubinDirect",
    },
    {
      test: "ALP/Alkaline phosphate",
      value: "",
      unit: "U/L",
      reference: ["Child: 54-369", "Adult: 42-141"],
      rowClass: "parent",
      key: "aLPAlkalinephosphate",
    },
    {
      test: "SGPT/ALT",
      value: "",
      unit: "U/L",
      reference: ["Male: upto 45", "Female: upto 35"],
      rowClass: "parent",
      key: "sGPTALT",
    },
    {
      test: "SGOT/AST",
      value: "",
      unit: "U/L",
      reference: ["Male: upto 35", "Female: upto 31 U/L"],
      rowClass: "parent",
      key: "sGOTAST",
    },
    {
      test: "CPK - MB",
      value: "",
      unit: "U/L",
      reference: ["Male: upto 195", "Female: upto 170"],
      rowClass: "parent",
      key: "cPKMB",
    },
    {
      test: "CPK - Nac",
      value: "",
      unit: "U/L",
      reference: ["Male: upto 24-195 Female: 24-170"],
      rowClass: "parent",
      key: "cPKNac",
    },
    {
      test: "Gamma - GT",
      value: "",
      unit: "U/L",
      reference: ["Male: <50", "Female: <30"],
      rowClass: "parent",
      key: "gammaGT",
    },
    {
      test: "LDH",
      value: "",
      unit: "U/L",
      reference: ["225-450"],
      rowClass: "parent",
      key: "lDH",
    },
    {
      test: "ADA",
      value: "",
      unit: "U/L",
      reference: ["<30"],
      rowClass: "parent",
      key: "aDA",
    },
    {
      test: "S. Amylase",
      value: "",
      unit: "",
      reference: ["9-50 Street Close Unit"],
      rowClass: "parent",
      key: "sAmylase",
    },
    {
      test: "S. Chloride",
      value: "",
      unit: "mmol/L",
      reference: ["98-107"],
      rowClass: "parent",
      key: "sChloride",
    },
    {
      test: "S. Total Protein",
      value: "",
      unit: "gm%",
      reference: ["5.8-8.0"],
      rowClass: "parent",
      key: "sTotalProtein",
    },
    {
      test: "S. Albumin",
      value: "",
      unit: "gm%",
      reference: ["3.5-5.3"],
      rowClass: "parent",
      key: "sAlbumin",
    },
    {
      test: "S.Globulin",
      value: "",
      unit: "gm%",
      reference: ["2.3-3.6"],
      rowClass: "parent",
      key: "sGlobulin",
    },
    {
      test: "A/G ratio",
      value: "",
      unit: "",
      reference: [""],
      rowClass: "parent",
      key: "aGratio",
    },
    {
      test: "S. Calcium",
      value: "",
      unit: "mg/dl",
      reference: ["8.7-11"],
      rowClass: "parent",
      key: "sCalcium",
    },
    {
      test: "Phosphorous",
      value: "",
      unit: "mg/dl",
      reference: ["Adult: 2.5-5.0", "Child: 4.0-7.0"],
      rowClass: "parent",
      key: "phosphorous",
    },
    {
      test: "25-hydroxy-Vitamin D",
      value: "",
      unit: "ng/ml",
      reference: [
        "<20: Deficient",
        "20-29: Insufficient",
        "30-70: Sufficient",
        "70-150: Overdose but not toxic",
        ">150: Toxic",
      ],
      rowClass: "parent",
      key: "25hydroxyVitaminD",
    },
    {
      test: "Vitamin B12",
      value: "",
      unit: "pg/ml",
      reference: ["New Born: 160-1300", "Adult: 200-835", ">60 yrs: 110-800"],
      rowClass: "parent",
      key: "vitaminB12",
    },
    {
      test: "S.Ferritin",
      value: "",
      unit: "ng/ml",
      reference: ["Male: 25-250", "Female: 13-232"],
      rowClass: "parent",
      key: "sFerritin",
    },
    {
      test: "HDL",
      value: "",
      unit: "mg/dl",
      reference: ["Male: 35-45", "Female: 45-65"],
      rowClass: "parent",
      key: "hDL",
    },
  ],
  key: "biochemistry",
  tabTitle: "Biochemistry",
  viewConfig: [
    {
      title: "Test name",
      dataIndex: "test",
      width: "25%",
      editable: false,
    },
    {
      title: "Result",
      dataIndex: "value",
      width: "15%",
      editable: true,
    },
    {
      title: "Unit",
      dataIndex: "unit",
      width: "15%",
      editable: false,
    },
    {
      title: "Reference",
      dataIndex: "reference",
      width: "40%",
      editable: false,
    },
  ],
  reportView: {
    title: "Biochemistry",
    tableHeaders: [
      // { label: 'S.N', className: 'sn' },
      { label: "Test", className: "test" },
      { label: "Result", className: "input" },
      { label: "Unit", className: "unit" },
      { label: "Normal Value", className: "unit" },
    ],
  },
};

export default biochemistryConfig;
