const semenAnalysisConfig = {
  reports: [
    {
      test: "Collection time",
      value: "",
      unit: "",
      reference: [""],
      rowClass: "parent",
      key: "collectiontime",
    },
    {
      test: "Examination time",
      value: "",
      unit: "",
      reference: [""],
      rowClass: "parent",
      key: "examinationtime",
    },
    {
      test: "Volume",
      value: "",
      unit: "",
      reference: [""],
      rowClass: "parent",
      key: "volume",
    },
    {
      test: "Color",
      value: "",
      unit: "",
      reference: [""],
      rowClass: "parent",
      key: "color",
    },
    {
      test: "Reaction",
      value: "",
      unit: "",
      reference: [""],
      rowClass: "parent",
      key: "reaction",
    },
    {
      test: "Viscosity",
      value: "",
      unit: "",
      reference: [""],
      rowClass: "parent",
      key: "viscosity",
    },
    {
      test: "Liquidification",
      value: "",
      unit: " ",
      reference: [""],
      rowClass: "parent",
      key: "liquidification",
    },
    {
      test: "Motility",
      value: "",
      unit: "",
      reference: [""],
      rowClass: "parent",
      key: "motility",
    },
    {
      test: "Morphology",
      value: "",
      unit: "",
      reference: [""],
      rowClass: "parent",
      key: "morphology",
    },
    {
      test: "Sperm Count",
      value: "",
      unit: "",
      reference: [""],
      rowClass: "parent",
      key: "spermCount",
    },
  ],
  key: "semenAnalysis",
  tabTitle: "Semen Analysis",
  viewConfig: ["label", "input"],

  reportView: {
    title: "Semen Analysis",
    tableHeaders: [
      // { label: 'S.N', className: 'sn' },
      { label: "Test", className: "test" },
      { label: "Result", className: "input" },
      //   { label: 'Unit', className: 'unit' },
      //   { label: 'Normal Value', className: 'unit' },
    ],
  },
};

export default semenAnalysisConfig;
