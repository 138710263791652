const Header = ({ user, clinicInfo }) => {
  return (
    <div id="header">
      <div className="reg sub-title">
        Regd. No.: {clinicInfo.registrationID}
      </div>
      <center>
        <p>
          <span className="title">
            <strong>{clinicInfo.labName}</strong>
          </span>
          <br />
          <span className="sub-title">
            {clinicInfo.labAddress}
            {clinicInfo.labAddressHelper && (
              <>
                <br />
                {clinicInfo.labAddressHelper}
              </>
            )}
            <br />
            {clinicInfo.labContactInfo}
          </span>
        </p>
      </center>
      <hr />
      <table className="userinfo">
        <tbody>
          <tr>
            <td className="label-left">
              <label forhtml="_name">Name</label>
            </td>
            <td className="seperator">:</td>
            <td className="input">
              <span>{user.fullName}</span>
            </td>
            <td className="label-right">
              <label forhtml="age">Age</label>
            </td>
            <td className="seperator">:</td>
            <td className="sm">
              <span>{user.age}</span>
            </td>
          </tr>
          <tr>
            <td className="label-left">
              <label forhtml="_name">Referred By</label>
            </td>
            <td className="seperator">:</td>
            <td className="input">
              <span>{user.referredBy}</span>
            </td>
            <td className="label-right">
              <label forhtml="sex">Sex</label>
            </td>
            <td className="seperator">:</td>
            <td className="sm">
              <span>{user.sex}</span>
            </td>
          </tr>
          <tr>
            <td className="label-left">
              <label forhtml="_name">Date</label>
            </td>
            <td className="seperator">:</td>
            <td className="input">
              <span>{user.date}</span>
            </td>
            <td className="label-right">
              <label forhtml="printedDate">Report Printed Date</label>
            </td>
            <td className="seperator">:</td>
            <td className="sm">
              <span>{user.reportPrintedDate}</span>
            </td>
          </tr>
        </tbody>
      </table>

      <hr />
    </div>
  );
};

export default Header;
