const pageConstants = {
  home: {
    title: 'Home',
    icon: 'home',
    key: 'home',
  },
  newReport: {
    title: 'New Report',
    key: 'new-report',
    icon: 'file-add',
  },
  search: {
    title: 'Search',
    key: 'search',
    icon: 'search',
  },
};

export { pageConstants };
