// import userActions from './types/user';

import dataActionsType from './types/user';

export function updateUser(data) {
  return {
    type: dataActionsType.UPDATE_USER,
    payload: { key: 'user', data },
  };
}

export function resetUser() {
  return {
    type: dataActionsType.RESET_USER,
    payload: { key: 'user' },
  };
}
