const immunologyConfig = {
  reports: [
    {
      test: "Thyroid Function Test",
      value: "",
      unit: "",
      reference: "",
      rowClass: "parent",
      show: false,
      key: "thyroidFunctionTest",
    },
    {
      test: "FT3 ",
      value: "",
      unit: "pg/ml",
      reference: ["2.0-4.2"],
      rowClass: "child",
      key: "fT3",
      parentKey: "thyroidFunctionTest",
    },
    {
      test: "FT4 ",
      value: "",
      unit: "pg/ml",
      reference: ["8.9-17.2"],
      rowClass: "child",
      key: "fT4",
      parentKey: "thyroidFunctionTest",
    },
    {
      test: "T.S.H. ",
      value: "",
      unit: "microU/ml",
      reference: ["0.3-4.5"],
      rowClass: "child",
      key: "tSH",
      parentKey: "thyroidFunctionTest",
    },
    {
      test: "FT3",
      value: "",
      unit: "pg/ml",
      reference: ["1.4-4.2"],
      rowClass: "child",
      key: "fT3",
      parentKey: "thyroidFunctionTest",
    },
    {
      test: "FT4",
      value: "",
      unit: "ug/dl",
      reference: ["0.65-1.97"],
      rowClass: "child",
      key: "fT4",
      parentKey: "thyroidFunctionTest",
    },
    {
      test: "T.S.H.",
      value: "",
      unit: "uIU/ml",
      reference: ["0.39-6.16"],
      rowClass: "child",
      key: "tSH",
      parentKey: "thyroidFunctionTest",
    },
    {
      test: "Microalbumin",
      value: "",
      unit: "mg/L",
      reference: ["Upto 20"],
      rowClass: "parent",
      key: "microalbumin",
    },
    {
      test: "C-Reactive Protein",
      value: "",
      unit: "mg/L",
      reference: ["< 6.0"],
      rowClass: "parent",
      key: "cReactiveProtein",
    },
    {
      test: "Anti-Thyroidperoxide Antibody",
      value: "",
      unit: "IU/ml",
      reference: ["< 50"],
      rowClass: "parent",
      key: "antiThyroidperoxideAntibody",
    },
    {
      test: "Aso-Titre",
      value: "",
      unit: "IU/ml",
      reference: ["Adult: upto 200", "Children <5 years old : 100"],
      rowClass: "parent",
      key: "asoTitre",
    },
    {
      test: "S.Cortisol",
      value: "",
      unit: "ug/dl",
      reference: ["Adult: 5-23", "Child: 3-21", "New Born: 1-24"],
      rowClass: "parent",
      key: "sCortisol",
    },
    {
      test: "Total Testosterone",
      value: "",
      unit: "ng/dl",
      reference: ["Male: 241-827", "Female: 12-76"],
      rowClass: "parent",
      key: "totalTestosterone",
    },
    {
      test: "Free Testosterone",
      value: "",
      unit: "",
      reference: [],
      rowClass: "parent",
      key: "freeTestosterone",
    },
    {
      test: "L.H.(Luteinizing Hormone)",
      value: "",
      unit: "",
      reference: [
        "Male: 1.1-8.1",
        "Female:",
        "Leuteal Phase: 0.4-19.1",
        "Follicular Phase: 1.2-12.4",
        "Mid Cycle Phase: 12-82.01",
        "Post Menenophase: 14-18",
      ],
      rowClass: "parent",
      key: "lHLuteinizingHormone",
    },
    {
      test: "PRL(Prolactin)",
      value: "",
      unit: "uIU/ml",
      reference: ["Male: 54-340", "Female: 68-490", "Post-menopausal: 62-410"],
      rowClass: "parent",
      key: "pRLProlactin",
    },
    {
      test: "FSH(Follicle Stimulating Hormone)",
      value: "",
      unit: "mIU/ml",
      reference: [
        "Male: 1.7-12",
        "Female:",
        "OvulatingPeak(Do): 6.3-24",
        "Follicularphase:",
        "1st half(D-15 to D-9): 3.9-12.0",
        "2nd half(D-8 to D-2): 2.9-9.0",
        "Luteal Phase(D+3 to D+15): 1.5-70",
        "Menopause: 17-95",
      ],
      rowClass: "parent",
      key: "fSHFollicleStimulatingHormone",
    },
    {
      test: "Estradiol(E2)",
      value: "",
      unit: "pg/ml",
      reference: [
        "Male: <62.9",
        "Female:",
        "Follicularphase: 18-147",
        "Leutal phase: 43-214",
        "pre-ovulatory peak: 93-575",
        "post menopauseal: <58.0",
      ],
      rowClass: "parent",
      key: "estradiolE2",
    },
    {
      test: "PTH(Parathyroid)",
      value: "",
      unit: "pg/ml",
      reference: ["11.1-79.5"],
      rowClass: "parent",
      key: "pTHParathyroid",
    },
    {
      test: "Copper(Serum)",
      value: "",
      unit: "uml/L",
      reference: ["Male: 11-22", "Female: 12.6-24"],
      rowClass: "parent",
      key: "copperSerum",
    },
  ],
  key: "immunology",
  tabTitle: "Biochemistry",
  viewConfig: [
    {
      title: "Test name",
      dataIndex: "test",
      width: "25%",
      editable: false,
    },
    {
      title: "Result",
      dataIndex: "value",
      width: "15%",
      editable: true,
    },
    {
      title: "Unit",
      dataIndex: "unit",
      width: "15%",
      editable: false,
    },
    {
      title: "Reference",
      dataIndex: "reference",
      width: "40%",
      editable: false,
    },
  ],

  reportView: {
    title: "Biochemistry",
    tableHeaders: [
      // { label: 'S.N', className: 'sn' },
      { label: "Test", className: "test" },
      { label: "Result", className: "input" },
      { label: "Unit", className: "unit" },
      { label: "Normal Value", className: "unit" },
    ],
  },
};

export default immunologyConfig;
