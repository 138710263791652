import { combineReducers } from 'redux';

import ui from './ui';
import data from './data';

const reducers = combineReducers({
  ui,
  data,
});

export default reducers;
