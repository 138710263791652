const stoolAnalysisConfig = {
  reports: [
    {
      test: "Colour",
      value: "",
      unit: "",
      reference: [""],
      rowClass: "parent",
      key: "colour",
    },
    {
      test: "Consistency",
      value: "",
      unit: "",
      reference: [""],
      rowClass: "parent",
      key: "consistency",
    },
    {
      test: "Blood",
      value: "",
      unit: "",
      reference: [""],
      rowClass: "parent",
      key: "blood",
    },
    {
      test: "Mucus",
      value: "",
      unit: "",
      reference: [""],
      rowClass: "parent",
      key: "mucus",
    },
    {
      test: "Refucing Substance",
      value: "",
      unit: "",
      reference: [""],
      rowClass: "parent",
      key: "refucingSubstance",
    },
    {
      test: "Occult Blood Test",
      value: "",
      unit: "",
      reference: [""],
      rowClass: "parent",
      key: "occultBloodTest",
    },
    {
      test: "Microscopic Examination",
      value: "",
      unit: " ",
      reference: [""],
      rowClass: "parent",
      show: false,
      key: "microscopicExamination",
    },
    {
      test: "Ascaris Lumbricodes",
      value: "",
      unit: "",
      reference: [""],
      rowClass: "child",
      key: "ascarisLumbricodes",
      parentKey: "microscopicExamination",
    },
    {
      test: "Trichuris Lumbricoides",
      value: "",
      unit: "",
      reference: [""],
      rowClass: "child",
      key: "trichurisLumbricoides",
      parentKey: "microscopicExamination",
    },
    {
      test: "Trichuris Trichuria",
      value: "",
      unit: "",
      reference: [""],
      rowClass: "child",
      key: "trichurisTrichuria",
      parentKey: "microscopicExamination",
    },
    {
      test: "Enterobius vermicularis",
      value: "",
      unit: "",
      reference: [""],
      rowClass: "child",
      key: "enterobiusvermicularis",
      parentKey: "microscopicExamination",
    },
    {
      test: "B.coli",
      value: "",
      unit: "",
      reference: [""],
      rowClass: "child",
      key: "bcoli",
      parentKey: "microscopicExamination",
    },
    {
      test: "Hook Worm",
      value: "",
      unit: "",
      reference: [""],
      rowClass: "child",
      key: "hookWorm",
      parentKey: "microscopicExamination",
    },
    {
      test: "Tape Worm",
      value: "",
      unit: "",
      reference: [""],
      rowClass: "child",
      key: "tapeWorm",
      parentKey: "microscopicExamination",
    },
    {
      test: "Fat drops",
      value: "",
      unit: "/HPF",
      reference: [""],
      rowClass: "child",
      key: "fatdrops",
      parentKey: "microscopicExamination",
    },
    {
      test: "Others",
      value: "",
      unit: "",
      reference: [""],
      rowClass: "child",
      key: "others",
      parentKey: "microscopicExamination",
    },
    {
      test: "Giardia Lembella",
      value: "",
      unit: "",
      reference: [""],
      rowClass: "child",
      key: "giardiaLembella",
      parentKey: "microscopicExamination",
    },
    {
      test: "E. Histolytica",
      value: "",
      unit: "",
      reference: [""],
      rowClass: "child",
      key: "eHistolytica",
      parentKey: "microscopicExamination",
    },
    {
      test: "Pus Cell",
      value: "",
      unit: "/HPF",
      reference: [""],
      rowClass: "child",
      key: "pusCell",
      parentKey: "microscopicExamination",
    },
    {
      test: "RBC",
      value: "",
      unit: "/HPF",
      reference: [""],
      rowClass: "child",
      key: "rBC",
      parentKey: "microscopicExamination",
    },
    {
      test: "Macrophage",
      value: "",
      unit: "/HPF",
      reference: [""],
      rowClass: "child",
      key: "macrophage",
      parentKey: "microscopicExamination",
    },
    {
      test: "Yeast Cells",
      value: "",
      unit: "/HPF",
      reference: [""],
      rowClass: "child",
      key: "yeastCells",
      parentKey: "microscopicExamination",
    },
    {
      test: "Undigest Food Particles",
      value: "",
      unit: "",
      reference: [""],
      rowClass: "child",
      key: "undigestFoodParticles",
      parentKey: "microscopicExamination",
    },
    {
      test: "Others ",
      value: "",
      unit: "",
      reference: [""],
      rowClass: "parent",
      key: "others",
    },
  ],
  key: "stoolAnalysis",
  tabTitle: "Stool Report",
  viewConfig: [
    {
      title: "Test name",
      dataIndex: "test",
      width: "25%",
      editable: false,
    },
    {
      title: "Result",
      dataIndex: "value",
      width: "15%",
      editable: true,
    },
    {
      title: "Unit",
      dataIndex: "unit",
      width: "15%",
      editable: false,
    },
  ],
  reportView: {
    title: "Parasitology",
    subTitle: "Specimen: Stool",
    tableHeaders: [
      { label: "Test", className: "test" },
      { label: "Result", className: "input" },
      { label: "Unit", className: "unit" },
      // TODO: remove unit from final report
    ],
  },
};

export default stoolAnalysisConfig;
