import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useEffect, useState } from "react";
import { Card, Select, Button } from "antd";

import { connect } from "react-redux";

import _get from "lodash.get";

import { MenuOutlined, CloseOutlined } from "@ant-design/icons";

const { Option } = Select;

const all = [
  {
    content: "Amoxycillin",
    id: "amoxycillin",
  },
  {
    content: "Nalidixic Acid",
    id: "nalidixic-acid",
  },
  {
    content: "Chloramphenicol",
    id: "chloramphenicol",
  },
  {
    content: "Cefixime",
    id: "cefixime",
  },
  {
    content: "Ofloxacin",
    id: "ofloxacin",
  },
  {
    content: "Cephalexine",
    id: "cephalexine",
  },
  {
    content: "Ciprofloxacin",
    id: "ciprofloxacin",
  },
  {
    content: "Amoxyclav",
    id: "amoxyclav",
  },
  {
    content: "Ceftazidime",
    id: "ceftazidime",
  },
  {
    content: "Nitrofurantoin",
    id: "nitrofurantoin",
  },
  {
    content: "Azithromycin",
    id: "azithromycin",
  },
  {
    content: "Levofloxacin",
    id: "levofloxacin",
  },
  {
    content: "Amikacin",
    id: "amikacin",
  },
  {
    content: "Cephotaxime",
    id: "cephotaxime",
  },
  {
    content: "Gentamycin",
    id: "gentamycin",
  },
  {
    content: "Co-trimoxazol",
    id: "co-trimoxazol",
  },
  {
    content: "Norfloxacin",
    id: "norfloxacin",
  },
];

const getInitialReportSetup = () => {
  return {
    reportView: {
      title: "Microbiology",
      tableHeaders: [],
      subTitle: "",
    },
    resistant: [],
    sensitive: [],
  };
};

const reorder = (list, result) => {
  const newReport = JSON.parse(JSON.stringify(list));
  const [removed] = newReport[result.source.droppableId].splice(
    result.source.index,
    1
  );

  newReport[result.destination.droppableId].splice(
    result.destination.index,
    0,
    removed
  );
  return newReport;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  margin: `0 0 ${grid}px 0`,
  background: isDragging ? "rgba(232, 232, 232, .9)" : "rgba(244, 245, 247, 1)",
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: "#fefefe",
  padding: grid,
  width: "auto",
  minWidth: "39%",
  maxWidth: "50%",
  display: "inline-block",
  borderRadius: "5px",
});

const CultureReportForm = (props) => {
  const [report, setReport] = useState(getInitialReportSetup());
  useEffect(() => {
    props.updatedCultureData(report);
    // updateTestResult()
  }, [report]);

  const onDragEnd = (result) => {
    if (result.reason === "DROP") {
      if (!result.destination) {
        return;
      }
      const newReport = reorder(report, result);

      setReport(newReport);
    }
  };

  const selectCulture = (medId, cultureType) => {
    const existingMedInSensitive = report.sensitive.find(
      (med) => med.id === medId
    );
    const existingMedInResistant = report.resistant.find(
      (med) => med.id === medId
    );
    if (!existingMedInSensitive && !existingMedInResistant) {
      // add data in sensitive
      const medData = all.find((med) => med.id === medId);
      setReport({
        ...report,
        [cultureType]: [...report[cultureType], medData],
      });
    }
  };

  const isAddedInList = (medId) => {
    const existingMed = [...report.sensitive, ...report.resistant].find(
      (med) => med.id === medId
    );

    return existingMed;
  };

  const removeMed = (index, cultureType) => {
    const cloneList = report[cultureType].slice();
    cloneList.splice(index, 1);

    setReport({
      ...report,
      [cultureType]: [...cloneList],
    });
  };
  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="sensitive">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              <div>
                <h2>Sensitive</h2>
                <Select
                  showSearch
                  style={{ width: "100%", marginBottom: "10px" }}
                  placeholder="Search to add"
                  optionFilterProp="children"
                  value={null}
                  onSelect={(selectedMed) => {
                    selectCulture(selectedMed, "sensitive");
                  }}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                >
                  {all.map((med) => (
                    <Option
                      value={med.id}
                      disabled={isAddedInList(med.id)}
                      key={med.id}
                    >
                      {med.content}
                    </Option>
                  ))}
                </Select>
              </div>
              {report.sensitive.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <div
                        style={{
                          boxShadow:
                            "0.5px 0.5px 0.5px 0.5px rgba(0, 0, 0, 0.2)",
                          borderRadius: "3px",
                          padding: "6px",
                          background: "white",
                          border: "1px solid rgba(0, 0, 0, 0.2)",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <span>{index + 1}. </span>
                          <span>{item.content}</span>
                        </div>
                        <Button
                          type="text"
                          icon={<CloseOutlined style={{ color: "#aeaeae" }} />}
                          onClick={() => removeMed(index, "sensitive")}
                        />
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>

        <Droppable droppableId="resistant">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              <div>
                <h2>Resistant</h2>
                <Select
                  showSearch
                  style={{ width: "100%", marginBottom: "10px" }}
                  placeholder="Search to add"
                  optionFilterProp="children"
                  value={null}
                  onSelect={(selectedMed) => {
                    console.log("resistant select", selectedMed);
                    selectCulture(selectedMed, "resistant");
                  }}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                >
                  {all.map((med) => (
                    <Option
                      value={med.id}
                      disabled={isAddedInList(med.id)}
                      key={med.id}
                    >
                      {med.content}
                    </Option>
                  ))}
                </Select>
              </div>
              {report.resistant.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <div
                        style={{
                          boxShadow:
                            "0.5px 0.5px 0.5px 0.5px rgba(0, 0, 0, 0.2)",
                          borderRadius: "3px",
                          padding: "6px",
                          background: "white",
                          border: "1px solid rgba(0, 0, 0, 0.2)",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <span>{index + 1}. </span>
                          <span>{item.content}</span>
                        </div>
                        <Button
                          type="text"
                          icon={<CloseOutlined style={{ color: "#aeaeae" }} />}
                          onClick={() => removeMed(index, "resistant")}
                        />
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

const mapStateToProps = (state) => {
  return { report: _get(state, "data.report.microbiology", {}) };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // initReport: () =>
    //   dispatch({ type: "REPORT_INIT", payload: microBiologyConfig }),
    updateTestResult: (data) =>
      dispatch({
        type: "REPORT_UPDATE_RESULT",
        payload: { ...data, reportType: "microbiology" },
      }),
  };
};

// export default connect(mapStateToProps, mapDispatchToProps)(CultureReportForm);

export default CultureReportForm;
