import React from "react";
import { connect } from "react-redux";
import { Steps, Card, Tabs } from "antd";

import UserInfo from "../../userInfo";
import ReportEntry from "../reportEntry";
import ReportPreview from "../reportPreview";
import { pageConstants } from "constants/page";
import * as userActions from "../../../actions/user";
import { withMainWrapper } from "../../../hoc/withMainWrapper";
import { resetAllReport, removeReportActions } from "../../../actions/data";

const { Step } = Steps;
const { TabPane } = Tabs;

const tabsConfig = {
  info: {
    title: `Patient's Information`,
    tabKey: "info",
    tabIndex: 0,
  },
  report: {
    title: `Report`,
    tabKey: "report",
    tabIndex: 1,
  },
  preview: {
    title: "Preview",
    tabKey: "preview",
    tabIndex: 2,
  },
  print: {
    title: `Print`,
    tabKey: "print",
    tabIndex: 3,
  },
};

class NewReport extends React.Component {
  state = {
    key: tabsConfig.info.tabKey,
    selectedTabIndex: 0,
  };

  onTabChange = (key) => {
    const selectedTabObj = tabsConfig[key];
    this.setState({
      key: selectedTabObj.tabKey,
      selectedTabIndex: selectedTabObj.tabIndex,
    });
  };

  render() {
    const step = Object.keys(tabsConfig).map((tabKey, index) => (
      <Step title={tabsConfig[tabKey].title} key={index} />
    ));

    return (
      <div>
        <Card style={{ borderRadius: "5px" }}>
          <Steps size="small" current={this.state.selectedTabIndex}>
            {step}
          </Steps>
        </Card>
        <br />
        <Card>{this._getContent(this.state.key)}</Card>
      </div>
    );
  }

  _getContent = () => {
    // Get from current redux or from component state
    const { fullName, age, referredBy, sex, date, reportPrintedDate } =
      this.props.user;
    const values = {
      fullName,
      age,
      referredBy,
      sex,
      date,
      reportPrintedDate,
    };

    return (
      <Tabs
        defaultActiveKey={tabsConfig.info.key}
        activeKey={this.state.key}
        onTabClick={this.onTabChange}
      >
        <TabPane tab={tabsConfig.info.title} key={tabsConfig.info.tabKey}>
          <UserInfo
            values={values}
            onSubmit={this._onSubmitUserData}
            onUpdate={this._onUpdateUserData}
            _resetUserData={this._onResetUserData}
          />
        </TabPane>
        <TabPane tab={tabsConfig.report.title} key={tabsConfig.report.tabKey}>
          <ReportEntry
            reports={this.props.reports}
            onSubmit={this._onSubmitReport}
            // canPreview={Object.keys(this.props.reports).length !== 0}
            removeReport={this.props._removeReport}
            onReset={this.props._resetData}
          />
        </TabPane>
        <TabPane tab={tabsConfig.preview.title} key={tabsConfig.preview.tabKey}>
          <ReportPreview
            onSubmit={this._onSubmitPreview}
            _onSubmitPrint={this._onSubmitPrint}
          />
        </TabPane>
        <TabPane tab={tabsConfig.print.title} key={tabsConfig.print.tabKey}>
          ----
        </TabPane>
      </Tabs>
    );
  };

  _onUpdateUserData = (location, values) => {
    this.props._saveUserData(values);
  };

  _onSubmitUserData = (location, values) => {
    this.props._saveUserData(values);
    // TODO: validate values for empty if fails done send to next
    // TODO: or show warning message

    this.onTabChange(tabsConfig.report.tabKey);
  };

  _onSubmitReport = (values) => {
    if (Object.keys(this.props.reports).length === 0) {
      return;
    }
    this.onTabChange(tabsConfig.preview.tabKey);
  };

  _onSubmitPreview = (values) => {
    this.onTabChange(tabsConfig.print.tabKey);
  };

  _onSubmitPrint = () => {
    this.onTabChange(tabsConfig.info.tabKey);
  };

  _onResetUserData = () => {
    this.props._resetUserData();
  };
}

const mapStateToProps = (state) => {
  return {
    user: state.data.user,
    reports: state.data.report,
  };
};

const mapDispatchToProps = (dispatch) => ({
  _saveUserData: (data) => {
    return dispatch(userActions.updateUser(data));
  },
  _resetUserData: () => {
    return dispatch(userActions.resetUser());
  },
  _removeReport: (reportKey) => {
    console.log("reportKey: ", reportKey);
    return dispatch(removeReportActions(reportKey));
  },
  _resetData: () => {
    return dispatch(resetAllReport());
  },
});

export default withMainWrapper(
  connect(mapStateToProps, mapDispatchToProps)(NewReport),
  pageConstants.newReport.key
);
