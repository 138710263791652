// import { dataActions } from '../actions/types/ui';
import dataActionTypes from "../actions/types/data";
import userActionTypes from "../actions/types/user";
import reportActionTypes from "../actions/types/report";

const initialState = {
  user: {
    fullName: "",
    age: "",
    referredBy: "",
    sex: "",
    date: "",
    reportPrintedDate: "",
  },
  initialUser: {
    fullName: "",
    age: "",
    referredBy: "",
    sex: "",
    date: "",
    reportPrintedDate: "",
  },
  report: {},
};

function reducer(state = initialState, action) {
  let finalState = {};
  switch (action.type) {
    case userActionTypes.UPDATE_USER:
      if (action.payload.key === "user") {
        finalState = {
          ...state,
          user: { ...state.user, ...action.payload.data },
        };
        return finalState;
      }

      return state;
    case userActionTypes.RESET_USER: {
      if (action.payload.key === "user") {
        finalState = { ...state, user: { ...state.initialUser } };
        return finalState;
      }
      return state;
    }
    case reportActionTypes.REPORT_INIT: {
      return {
        ...state,
        report: {
          ...state.report,
          [action.payload.key]: { ...action.payload },
        },
      };
    }
    case reportActionTypes.REPORT_UPDATE_RESULT: {
      if (action.payload.reportType === "microbiology") {
        return {
          ...state,
          report: {
            ...state.report,
            [action.payload.reportType]: action.payload,
          },
        };
      }

      const row = action.payload.test;
      const newData = [...state.report[action.payload.reportType].reports];
      const index = newData.findIndex((item) => row.key === item.key);
      const item = newData[index];
      newData.splice(index, 1, {
        ...item,
        ...row,
      });

      // Note: specific key update can't be done because of the array composition
      return {
        ...state,
        report: {
          ...state.report,
          [action.payload.reportType]: {
            ...state.report[action.payload.reportType],
            reports: newData,
          },
        },
      };
    }
    case reportActionTypes.REPORT_REMOVE:
      const cloneReport = Object.assign(state.report);
      delete cloneReport[action.payload.removeKey];

      return { ...state, report: cloneReport };
    case reportActionTypes.REPORT_RESET_DATA:
      return { ...state, report: {} };
    default:
      return state;
  }
}

export default reducer;
