// import React from 'react';

import _get from "lodash.get";
import { connect } from "react-redux";
import React, { useEffect, useState, useRef, useContext } from "react";
import { Col, Table, Form, Input, Radio } from "antd";

import microBiologyConfig from "reportConfig/microbiology";
import CultureReportForm from "./CultureReportForm";

const Microbiology = (props) => {
  const [microbiology, setMicrobiology] = useState(microBiologyConfig);
  useEffect(() => {
    props.initReport();
  }, []);

  const saveTestData = (data, field) => {
    // Save field data
    setMicrobiology({
      ...microbiology,
      testData: {
        ...microbiology.testData,
        [field]: data,
      },
    });
  };

  useEffect(() => {
    props.updateTestResult(microbiology);
  }, [microbiology]);

  const handleStainDataSave = (row) => {
    const changeTestIndex = microbiology.stainConfig.reports.findIndex(
      (report) => report.key === row.key
    );

    const newReports = [...microbiology.stainConfig.reports];

    newReports.splice(changeTestIndex, 1, {
      ...row,
      value: row.value.trim(),
    });

    const newData = {
      ...microbiology,
      stainConfig: {
        ...microbiology.stainConfig,
        reports: newReports,
      },
    };
    setMicrobiology(newData);
  };

  const getTableForStainEntry = () => {
    const stainColumn = microbiology.stainConfig.viewConfig;
    const components = {
      body: {
        cell: EditableCell,
        row: EditableFormRow,
      },
    };

    const columns = stainColumn.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => {
          return {
            record,
            editable: _get(record, "show", true),
            dataIndex: col.dataIndex,
            title: col.title,
            handleSave: handleStainDataSave,
          };
        },
      };
    });

    return (
      <Table
        components={components}
        bordered
        pagination={false}
        dataSource={microbiology.stainConfig.reports}
        columns={columns}
        rowClassName="editable-row"
      />
    );
  };

  const updatedCultureData = (cultureReport) => {
    const newMicrobiologyData = {
      ...microbiology,
      cultureConfig: {
        ...cultureReport,
      },
    };

    setMicrobiology(newMicrobiologyData);
  };
  const getCultureView = () => {
    switch (microbiology.testData.selectedTestType) {
      case microbiology.testType.stain.key:
        return <>{getTableForStainEntry()}</>;
      case microbiology.testType.culture.key:
        return <CultureReportForm updatedCultureData={updatedCultureData} />;
      default:
        return <></>;
    }
  };

  return (
    <>
      <Col span={18}>
        <Form
          style={{ borderBottom: "1px solid #f0f0f0" }}
          labelCol={{
            span: 5,
          }}
          wrapperCol={{
            span: 13,
          }}
          layout="horizontal"
          size={"large"}
        >
          <Form.Item label="Specimen">
            <Input
              onPressEnter={(e) => saveTestData(e.target.value, "specimen")}
              onBlur={(e) => saveTestData(e.target.value, "specimen")}
            />
          </Form.Item>
          <Form.Item label="Test">
            <Input
              onPressEnter={(e) => saveTestData(e.target.value, "test")}
              onBlur={(e) => saveTestData(e.target.value, "test")}
            />
          </Form.Item>
          <Form.Item label="Test Result">
            <Input
              //   span={7}
              onPressEnter={(e) => saveTestData(e.target.value, "testResult")}
              onBlur={(e) => saveTestData(e.target.value, "testResult")}
            />
          </Form.Item>
          <Form.Item label="Microbiology Type" name="size">
            <Radio.Group
              buttonStyle="solid"
              onChange={(e) => {
                saveTestData(e.target.value, "selectedTestType");
              }}
            >
              <Radio.Button value={microbiology.testType.stain.key}>
                {microbiology.testType.stain.label}
              </Radio.Button>
              <Radio.Button value={microbiology.testType.culture.key}>
                {microbiology.testType.culture.label}
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Form>
        <p
          style={{
            marginTop: "-14px",
            background: "#fff",
            display: "inline-block",
            position: "absolute",
            marginLeft: "16px",
            padding: "1px 8px",
          }}
        >
          {_get(
            microbiology.testType[microbiology.testData.selectedTestType],
            "label",
            ""
          )}
        </p>
        <div style={{ paddingTop: "8px" }}>{getCultureView()}</div>
      </Col>
    </>
  );
};

const mapStateToProps = (state) => {
  return { report: _get(state, "data.report.microbiology", {}) };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initReport: () =>
      dispatch({ type: "REPORT_INIT", payload: microBiologyConfig }),
    updateTestResult: (data) =>
      dispatch({
        type: "REPORT_UPDATE_RESULT",
        payload: { ...data, reportType: "microbiology" },
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Microbiology);

const EditableContext = React.createContext();

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();

  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableFormRow = EditableRow;

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(true);
  const inputRef = useRef();
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      // inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async (e) => {
    try {
      const values = await form.validateFields();
      handleSave({ ...record, ...values });
    } catch (errInfo) {}
  };

  let childNode = children;

  if (editable) {
    childNode = (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: false,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input
          ref={inputRef}
          onPressEnter={save}
          defaultValue={record.value}
          onBlur={save}
        />
      </Form.Item>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};
