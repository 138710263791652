const haematologyConfig = {
  reports: [
    {
      test: "Haemoglobin",
      value: "",
      unit: "g/dl",
      reference: ["Male: 13-18", "F: 12-16 g/dl"],
      rowClass: "parent",
      key: "haemoglobin",
    },
    {
      test: "MCV",
      value: "",
      unit: "/cu mm",
      reference: ["82-92"],
      rowClass: "parent",
      key: "mCV",
    },
    {
      test: "PCV",
      value: "",
      unit: "%",
      reference: ["Male: 42-52", "Female: 36-48"],
      rowClass: "parent",
      key: "pCV",
    },
    {
      test: "WBC",
      value: "",
      unit: "/cu mm",
      reference: ["4000-11000"],
      rowClass: "parent",
      key: "wBC",
    },
    {
      test: "RBC",
      value: "",
      unit: "Million/cu mm",
      reference: ["Male: 4.5-5.5", "F: 3.0-4.5"],
      rowClass: "parent",
      key: "rBC",
    },
    {
      test: "Platelets",
      value: "",
      unit: "/cu mm",
      reference: ["1.50-4.50 Lakh"],
      rowClass: "parent",
      key: "platelets",
    },
    {
      test: "Differential Cell Count",
      value: "",
      unit: "",
      reference: [""],
      rowClass: "parent",
      show: false,
      key: "differentialCellCount",
    },
    {
      test: "Band Cell",
      value: "",
      unit: "%",
      reference: [""],
      rowClass: "child",
      key: "bandCell",
      parentKey: "differentialCellCount",
    },
    {
      test: "Neutrophil",
      value: "",
      unit: "%",
      reference: ["45-70"],
      rowClass: "child",
      key: "neutrophil",
      parentKey: "differentialCellCount",
    },
    {
      test: "Lymphocyte",
      value: "",
      unit: "%",
      reference: ["25-40"],
      rowClass: "child",
      key: "lymphocyte",
      parentKey: "differentialCellCount",
    },
    {
      test: "Eosinophil",
      value: "",
      unit: "%",
      reference: ["1-5"],
      rowClass: "child",
      key: "eosinophil",
      parentKey: "differentialCellCount",
    },
    {
      test: "Monocyte",
      value: "",
      unit: "%",
      reference: ["2-8"],
      rowClass: "child",
      key: "monocyte",
      parentKey: "differentialCellCount",
    },
    {
      test: "Basophil",
      value: "",
      unit: "%",
      reference: ["0-1"],
      rowClass: "child",
      key: "basophil",
      parentKey: "differentialCellCount",
    },
    {
      test: "ESR",
      value: "",
      unit: "mm/1st hour",
      reference: ["Male: 0-9", "Female: 0-20"],
      rowClass: "parent",
      key: "esr",
    },
    {
      test: "HbA1c",
      value: "",
      unit: "%",
      reference: ["4.2-6.4", "Control <7.0"],
      rowClass: "parent",
      key: "hbA1c",
    },
    {
      test: "Mean Blood Glucose",
      value: "",
      unit: "",
      reference: [""],
      rowClass: "parent",
      key: "meanBloodGlucose",
    },
    {
      test: "B.T.",
      value: "",
      unit: "min-sec",
      reference: ["2-7"],
      rowClass: "parent",
      key: "bT",
    },
    {
      test: "C.T.",
      value: "",
      unit: "min-sec",
      reference: ["5-10"],
      rowClass: "parent",
      key: "cT",
    },
    {
      test: "APPT",
      value: "",
      unit: "sec",
      reference: ["20-40"],
      rowClass: "parent",
      key: "aPPT",
    },
    {
      test: "Prothrombin Time",
      value: "",
      unit: "sec",
      reference: ["12-14", "Control: 12"],
      rowClass: "parent",
      key: "prothrombinTime",
    },
    {
      test: "INR",
      value: "",
      unit: "",
      reference: [""],
      rowClass: "parent",
      key: "iNR",
    },
    {
      test: "Reticulocyte Count",
      value: "",
      unit: "sec",
      reference: [""],
      rowClass: "parent",
      key: "reticulocyteCount",
    },
    {
      test: "L.E. Prepn",
      value: "",
      unit: "",
      reference: [""],
      rowClass: "parent",
      key: "lEPrepn",
    },
    {
      test: "Malarial Parasite",
      value: "",
      unit: "",
      reference: [""],
      rowClass: "parent",
      key: "malarialParasite",
    },
    {
      test: "Blood Group & Type",
      value: "",
      unit: "",
      reference: [""],
      rowClass: "parent",
      key: "bloodGroupType",
    },
    {
      test: "Comment",
      value: "",
      unit: "",
      reference: [""],
      rowClass: "parent",
      key: "comment",
    },
  ],
  key: "haematology",
  tabTitle: "Haematology",
  viewConfig: [
    {
      title: "Test name",
      dataIndex: "test",
      width: "25%",
      editable: false,
    },
    {
      title: "Result",
      dataIndex: "value",
      width: "15%",
      editable: true,
    },
    {
      title: "Unit",
      dataIndex: "unit",
      width: "15%",
      editable: false,
    },
    {
      title: "Reference",
      dataIndex: "reference",
      width: "40%",
      editable: false,
    },
  ],
  reportView: {
    title: "Haematology",
    tableHeaders: [
      // { label: 'S.N', className: 'sn' },
      { label: "Test", className: "test" },
      { label: "Result", className: "input" },
      { label: "Unit", className: "unit" },
      { label: "Normal Value", className: "unit" },
    ],
  },
};

export default haematologyConfig;

// Conversion function
// Replaces non alphabet with empty char(removes space, and special) dont know about number
// var n = a.map(test => { var patt = /[^\w*]/g;var lower = test.test.replace(patt, ''); lower=lower.charAt(0).toLowerCase() + lower.slice(1);console.log(lower);return {...test, key: lower} })
// Inputs
// [
//   {
//     test: 'Haemoglobin',
//     unit: 'g/dl',
//     reference: ['Male: 13-18', 'F: 12-16 g/dl'],
//     rowClass: 'parent',
//   },
//   {
//     test: 'MCV',
//     unit: '/cu mm',
//     reference: ['82-92'],
//     rowClass: 'parent',
//   },
//   {
//     test: 'PCV',
//     unit: '%',
//     reference: ['Male: 42-52', 'Female: 36-48'],
//     rowClass: 'parent',
//   },
//   { test: 'WBC', unit: '/cu mm', reference: ['4000-11000'], rowClass: 'parent' },
//   { test: 'RBC', unit: 'Million/cu mm', reference: ['Male: 4.5-5.5', 'F: 3.0-4.5'], rowClass: 'parent' },
//   { test: 'Platelets', unit: '/cu mm', reference: ['1.50-4.50 Lakh'], rowClass: 'parent' },
//   { test: 'Differential Cell Count', unit: ' ', reference: [' '], rowClass: 'parent', show: '0' },
//   { test: 'Band Cell', unit: '%', reference: [''], rowClass: 'child' },
//   { test: 'Neutrophil', unit: '%', reference: ['45-70'], rowClass: 'child' },
//   { test: 'Lymphocyte', unit: '%', reference: ['25-40'], rowClass: 'child' },
//   { test: 'Eosinophil', unit: '%', reference: ['1-5'], rowClass: 'child' },
//   { test: 'Monocyte', unit: '%', reference: ['2-8'], rowClass: 'child' },
//   { test: 'Basophil', unit: '%', reference: ['0-1'], rowClass: 'child' },
//   { test: 'ESR', unit: 'mm/1st hour', reference: ['Male: 0-9', 'Female: 0-20'], rowClass: 'parent' },
//   { test: 'HbA1c', unit: '%', reference: ['4.2-6.4', 'Control <7.0'], rowClass: 'parent' },
//   { test: 'Mean Blood Glucose', unit: '', reference: [''], rowClass: 'parent' },
//   { test: 'B.T.', unit: 'min-sec', reference: ['2-7'], rowClass: 'parent' },
//   { test: 'C.T.', unit: 'min-sec', reference: ['5-10'], rowClass: 'parent' },
//   { test: 'APPT', unit: 'sec', reference: ['20-40'], rowClass: 'parent' },
//   { test: 'Prothrombin Time', unit: 'sec', reference: ['12-14', 'Control: 12'], rowClass: 'parent' },
//   { test: 'INR', unit: '', reference: [''], rowClass: 'parent' },
//   { test: 'Reticulocyte Count', unit: 'sec', reference: [''], rowClass: 'parent' },
//   { test: 'L.E. Prepn', unit: '', reference: [''], rowClass: 'parent' },
//   { test: 'Malarial Parasite', unit: '', reference: [''], rowClass: 'parent' },
//   { test: 'Blood Group & Type', unit: '', reference: [''], rowClass: 'parent' },
//   { test: 'Comment', unit: '', reference: [''], rowClass: 'parent' },
// ],
