import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Home from './components/content/home';
import { pageConstants } from 'constants/page';
import NewReport from './components/content/newReport';
import SearchContent from './components/content/searchContent';

class AppRouter extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<NewReport />} />
          <Route exact path={`/${pageConstants.home.key}`} element={<Home />} />
          <Route exact path={`/${pageConstants.search.key}`} element={<SearchContent />} />
          <Route exact path={`/${pageConstants.newReport.key}`} element={<NewReport />} />
        </Routes>
      </BrowserRouter>
    );
  }
}

export { AppRouter };
