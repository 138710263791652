import React from "react";
import _get from "lodash.get";
import { connect } from "react-redux";
import { Row, Col, Table, Form, Input } from "antd";

import { useContext, useState, useEffect, useRef } from "react";

import haematologyConfig from "../../../../../reportConfig/haematology";

import { capitalizeFirstLetter } from "../../../../../utils/string";

class Haematology extends React.Component {
  // constructor(props) {
  //   super(props);

  // this.columns = ;
  // }

  componentDidMount() {
    this.props.initReport();
  }

  render() {
    return <>{this._getTable(this.props.report.reports)}</>;
  }

  // _getHeader = (config) => {
  //   return (
  //     <Row gutter={16}>
  //       {config.map((viewType, index) => (
  //         <Col className="gutter-row" span={6} key={index}>
  //           <strong>asdf{capitalizeFirstLetter(viewType)}</strong>
  //         </Col>
  //       ))}
  //     </Row>
  //   );
  // };

  handleSave = (row) => {
    this.props.updateTestResult(row);
  };

  _getTable = (reports) => {
    const components = {
      body: {
        cell: EditableCell,
        row: EditableFormRow,
      },
    };

    const columns = haematologyConfig.viewConfig.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => {
          return {
            record,
            editable: _get(record, "show", true),
            dataIndex: col.dataIndex,
            title: col.title,
            handleSave: this.handleSave,
          };
        },
      };
    });

    return (
      <Table
        components={components}
        bordered
        pagination={false}
        dataSource={reports}
        columns={columns}
        rowClassName="editable-row"
        // onRow={(record, index) => {
        // let style = record.testkoClas === 'child' ? { color: 'blue', marginLeft: 10 } : { margin: 0 };
        // return { style: style };
        // }}
      />
    );
  };
}

const mapStateToProps = (state) => {
  return { report: _get(state, "data.report.haematology", {}) };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initReport: () =>
      dispatch({ type: "REPORT_INIT", payload: haematologyConfig }),
    updateTestResult: (data) =>
      dispatch({
        type: "REPORT_UPDATE_RESULT",
        payload: { test: data, reportType: "haematology" },
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Haematology);

const EditableContext = React.createContext();

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();

  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableFormRow = EditableRow;

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(true);
  const inputRef = useRef();
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      // inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async (e) => {
    try {
      // console.log("values", form);
      const values = await form.validateFields();
      // toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      // console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: false,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input
          ref={inputRef}
          onPressEnter={save}
          defaultValue={""}
          onBlur={save}
        />
      </Form.Item>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

// class EditableCell extends React.Component {
//   state = {
//     editing: true,
//   };

//   toggleEdit = () => {
//     const editing = !this.state.editing;
//     this.setState({ editing }, () => {
//       if (editing) {
//         this.input.focus();
//       }
//     });
//   };

//   save = e => {
//     const { record, handleSave } = this.props;
//     this.form.validateFields((error, values) => {
//       if (error && error[e.currentTarget.id]) {
//         return;
//       }
//       //this.toggleEdit();
//       handleSave({ ...record, ...values });
//     });
//   };

//   renderCell = form => {
//     this.form = form;
//     const { children, dataIndex, record, title } = this.props;
//     const editing = this.props.record.show === '0' ? false : true; //this.state;

//     console.log('dataIndex:-------------', editing, dataIndex);
//     return editing ? (
//       <Form.Item
//         style={{ margin: 0 }}
//         name={dataIndex}
//         rules={[
//           {
//             required: false,
//             message: `${title} is required.`,
//           },
//         ]}>
//         <Input ref={node => (this.input = node)} onPressEnter={this.save} onBlur={this.save} />
//       </Form.Item>
//     ) : (
//       <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={this.toggleEdit}>
//         {children}
//       </div>
//     );
//   };

//   render() {
//     // cell for editable content
//     const { editable, dataIndex, title, record, index, handleSave, children, ...restProps } = this.props;
//     let style = {}; //_get(record, 'testkoClas', 'parent') === 'child' ? { color: 'red', padding: 50 } : {};
//     // console.log('record', _get(record, 'testkoClas', ''), record);
//     // const style = { color: 'red' };
//     return (
//       <td {...restProps} style={style}>
//         {editable ? <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer> : children}
//       </td>
//     );
//   }
// }
