import React from 'react';
import { Input } from 'antd';

import { pageConstants } from 'constants/page';
import { withMainWrapper } from 'hoc/withMainWrapper';

const { Search } = Input;

class SearchContent extends React.Component {
  render() {
    return (
      <div>
        <Search placeholder={'Name, Date, Report type'} size="large" enterButton></Search>
      </div>
    );
  }
}

export default withMainWrapper(SearchContent, pageConstants.search.key);
