import React from "react";
import { Formik, Form, Field } from "formik";
import { Input, Row, Col, Button, Modal } from "antd";

class UserInfo extends React.Component {
  state = {
    savingData: false,
    askConfirmation: false,
  };
  render() {
    const { fullName, age, referredBy, sex, date, reportPrintedDate } =
      this.props.values;
    const values = { fullName, age, referredBy, sex, date, reportPrintedDate };
    console.log("values: ", values);
    return (
      <div>
        <Modal
          title="Are you sure?"
          visible={this.state.askConfirmation}
          onOk={this._confirmReset}
          onCancel={this._resetCancel}
          okButtonProps={{ disabled: false }}
          cancelButtonProps={{ disabled: false }}
        >
          <p>Are you sure to delete the user data?</p>
        </Modal>
        <Formik
          enableReinitialize={true}
          initialValues={values}
          onSubmit={(values) => {
            this._onSubmit(values);
          }}
        >
          {({ isSubmitting, setFieldValue, handleBlur, values }) => (
            <Form>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <label>Full Name</label>
                  <Field
                    type="text"
                    name="fullName"
                    as={MyInput}
                    onBlur={(e) => {
                      handleBlur(e);
                      setFieldValue("fullName", e.target.value);
                      this._onUpdate(values);
                    }}
                  />
                </Col>
                <Col span={12}>
                  <label>Age</label>
                  <Field
                    type="text"
                    name="age"
                    as={MyInput}
                    onBlur={(e) => {
                      handleBlur(e);
                      setFieldValue("age", e.target.value);
                      this._onUpdate(values);
                    }}
                  />
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <label>Referred By</label>
                  <Field
                    type="text"
                    name="referredBy"
                    as={MyInput}
                    onBlur={(e) => {
                      handleBlur(e);
                      setFieldValue("referredBy", e.target.value);
                      this._onUpdate(values);
                    }}
                  />
                </Col>
                <Col span={12}>
                  <label>Sex</label>
                  <Field
                    type="text"
                    name="sex"
                    as={MyInput}
                    onBlur={(e) => {
                      handleBlur(e);
                      setFieldValue("sex", e.target.value);
                      this._onUpdate(values);
                    }}
                  />
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <label>Date</label>
                  <Field
                    type="text"
                    name="date"
                    as={MyInput}
                    onBlur={(e) => {
                      handleBlur(e);
                      setFieldValue("date", e.target.value);
                      this._onUpdate(values);
                    }}
                  />
                </Col>
                <Col span={12}>
                  <label>Report Printed Date</label>
                  <Field
                    type="text"
                    name="reportPrintedDate"
                    as={MyInput}
                    onBlur={(e) => {
                      handleBlur(e);
                      setFieldValue("reportPrintedDate", e.target.value);
                      this._onUpdate(values);
                    }}
                  />
                </Col>
              </Row>

              <Row
                gutter={[16, 16]}
                type="flex"
                justify="end"
                style={{ marginTop: 8 }}
              >
                <Col span={4}>
                  <Button
                    block={true}
                    size={"large"}
                    type={"danger"}
                    onClick={() => {
                      this._askConfirmation(setFieldValue);
                    }}
                    // loading={this.state.savingData}
                    // disabled={this.state.savingData}
                  >
                    Reset
                  </Button>
                </Col>
                <Col span={5}>
                  <Button
                    block={true}
                    htmlType={"submit"}
                    size={"large"}
                    type={"primary"}
                    loading={this.state.savingData}
                    disabled={this.state.savingData}
                  >
                    Next
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
  _confirmReset = () => {
    Object.keys(this.props.values).forEach((field) =>
      this.setFieldValue(field, "")
    );

    this.props._resetUserData();

    this.setState({ askConfirmation: false });
  };

  _resetCancel = () => {
    this.setState({ askConfirmation: false });
  };

  _askConfirmation = (setFieldValue) => {
    // For resetting the form values
    this.setFieldValue = setFieldValue;
    this.setState({ askConfirmation: true });
  };

  _onUpdate = (values) => {
    this.props.onUpdate("report", values);
  };

  _onSubmit = (values) => {
    console.log("values: ", values);
    this.setState({ savingData: true });
    setTimeout(() => {
      this.props.onSubmit("report", values);
      this.setState({ savingData: false });
    }, 200);
  };
}

const MyInput = ({ field, form, ...props }) => {
  return <Input {...field} {...props} size={"large"} />;
};

export default UserInfo;
