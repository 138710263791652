import React from "react";
import _get from "lodash.get";
import { Formik, Form, Field } from "formik";
import { Collapse, Input, Row, Col, Button, Modal, Select, Card } from "antd";

import Haematology from "./reports/haematology";
import Biochemistry from "./reports/biochemistry/index.js";
import Immunology from "./reports/immunology";
import SemenAnalysis from "./reports/semenAnalysis/SemenAnalysis";
import Serology from "./reports/serology";
import StoolAnalysis from "./reports/stoolAnalysis";
import UrineAnalysis from "./reports/urineAnalysis";
import { Microbiology } from "./reports/microbiology";

import { MenuOutlined, CloseOutlined } from "@ant-design/icons";

const { Option } = Select;
const { Panel } = Collapse;

class ReportEntry extends React.Component {
  state = {
    activePanel: undefined,
    panel: [],
    reportData: [],
    reportList: [
      { title: "Haematology", key: "haematology" },
      { title: "Biochemistry", key: "biochemistry" },
      { title: "Serology", key: "serology" },
      { title: "Semen Analysis", key: "semenAnalysis" },
      { title: "Stool Report", key: "stoolAnalysis" },
      { title: "Urine Report", key: "urineAnalysis" },
      { title: "Microbiology", key: "microbiology" },
      { title: "Immunology", key: "immunology" },
    ],
    selectedReport: undefined,
    values: {},
    showReportSelection: false,
  };

  _onReportSelected = () => {
    const { selectedReport } = this.state;

    const newPanel = this.state.panel;
    // TODO: See the older report data and aggegrate with old
    if (this.state.panel.find((report) => report.key === selectedReport)) {
      return;
    }
    const reportData = this.state.reportList.find(
      (report) => report.key === selectedReport
    );
    if (!reportData) {
      return;
    }

    newPanel.push({
      headerTitle: reportData.title,
      key: reportData.key,
    });

    this.setState({
      panel: newPanel,
      showReportSelection: false,
      activePanel: selectedReport,
    });
  };

  removeButtons = (reportKey) => {
    return (
      <CloseOutlined
        onClick={() => {
          console.log(reportKey);
          this.removeReport(reportKey);
        }}
      />
    );
  };

  removeReport = (reportKey) => {
    const panelClone = this.state.panel.slice();
    const index = this.state.panel.findIndex(
      (panel) => panel.key === reportKey
    );
    panelClone.splice(index, 1);

    this.setState({ panel: panelClone });
    this.props.removeReport(reportKey);
  };

  render() {
    const { values } = this.state;
    return (
      <React.Fragment>
        <Modal
          title="Add report?"
          visible={this.state.showReportSelection}
          onOk={this._confirmAdd}
          onCancel={this._cancelAdd}
          okButtonProps={{
            disabled: false,
            htmlType: "submit",
            onClick: this._onReportSelected,
          }}
          cancelButtonProps={{ disabled: false }}
          destroyOnClose={false}
          maskClosable={false}
          mask={true}
        >
          <Formik
            initialValues={values}
            onSubmit={(values) => {
              // console.log("values", values);
              //   this._onSubmit(values);
            }}
          >
            {({ isSubmitting, setFieldValue }) => (
              <Form>
                <Select
                  name="reportTitle"
                  // initialValues={this.state.reportList[0].title}
                  placeholder="Please select a report"
                  onChange={(selected) => {
                    console.log(selected);
                    this.setState({ selectedReport: selected });
                  }}
                >
                  {this.state.reportList.map((report, index) => (
                    <Option key={report.key}>{report.title}</Option>
                  ))}
                </Select>
              </Form>
            )}
          </Formik>
        </Modal>
        <Row gutter={[8, 8]}>
          <Col span={6}>
            <Button
              block={true}
              htmlType={"submit"}
              size={"large"}
              type={"primary"}
              onClick={() => {
                this._askConfirmation();
              }}
            >
              + Add
            </Button>
          </Col>
          <Col span={5}>
            <Button
              block={true}
              htmlType={"submit"}
              size={"large"}
              type={"danger"}
              onClick={() => {
                this.setState({ panel: [] });
                this.props.onReset();
              }}
            >
              Reset
            </Button>
          </Col>
        </Row>
        <br />
        <Collapse
          accordion
          defaultActiveKey={[
            _get(this.state.panel[this.state.panel.length - 1], "key", 0),
          ]}
          activeKey={[this.state.activePanel]}
          onChange={this._accordionSelect}
        >
          {this.state.panel.map((panel) => {
            return (
              <Panel
                header={panel.headerTitle}
                key={panel.key}
                extra={this.removeButtons(panel.key)}
              >
                {this._getReportPanel(panel)}
              </Panel>
            );
          })}
        </Collapse>
        <br />
        <Card>
          <Row>
            <Col span={6} offset={18}>
              <Button
                block={true}
                htmlType={"submit"}
                size={"large"}
                type={"primary"}
                // disabled={this.props.canPreview}
                onClick={() => {
                  this.props.onSubmit("preview");
                }}
              >
                Preview
              </Button>
            </Col>
          </Row>
        </Card>
      </React.Fragment>
    );
  }

  _accordionSelect = (selected) => {
    this.setState({ activePanel: selected });
  };

  _getReportPanel = (panel) => {
    let view = null;
    switch (panel.key) {
      case "haematology": {
        view = <Haematology />;
        break;
      }
      case "biochemistry": {
        view = <Biochemistry />;
        break;
      }
      case "serology": {
        view = <Serology />;
        break;
      }
      case "immunology": {
        view = <Immunology />;
        break;
      }
      case "semenAnalysis": {
        view = <SemenAnalysis />;
        break;
      }
      case "stoolAnalysis": {
        view = <StoolAnalysis />;
        break;
      }
      case "urineAnalysis": {
        view = <UrineAnalysis />;
        break;
      }
      case "microbiology": {
        view = <Microbiology />;
        break;
      }

      default: {
        view = <p>Report mismatch </p>;
        break;
      }
    }
    return view;
  };

  _askConfirmation = () => {
    this.setState({ showReportSelection: true });
  };

  _confirmAdd = () => {
    this.setState({ showReportSelection: false });
  };

  _cancelAdd = () => {
    this.setState({ showReportSelection: false });
  };
}

export default ReportEntry;
