const microBiologyConfig = {
  key: "microbiology",
  tabTitle: "Microbiology",
  testData: {
    specimen: "",
    test: "",
    testResult: "",
    selectedTestType: "",
  },
  stainConfig: {
    viewConfig: [
      {
        title: "Day Count",
        dataIndex: "label",
        width: "25%",
        editable: false,
      },
      {
        title: "Result",
        dataIndex: "value",
        width: "25%",
        editable: true,
      },
    ],
    reports: [
      { label: "First Day : ", value: "", key: "firstDay" },
      { label: "Second Day : ", value: "", key: "secondDay" },
      { label: "Third Day : ", value: "", key: "thirdDay" },
      { label: "Forth Day : ", value: "", key: "forthDay" },
      { label: "Fifth Day : ", value: "", key: "fifthDay" },
      { label: "Sixth Day : ", value: "", key: "sixthDay" },
      { label: "Seventh Day : ", value: "", key: "seventhDay" },
      { label: "Eighth Day : ", value: "", key: "eigthDay" },
      { label: "Ninth Day : ", value: "", key: "ninthDay" },
    ],
    reportView: {
      title: "Microbiology",
      tableHeaders: [],
      subTitle: "",
    },
  },
  testType: {
    stain: {
      key: "stain",
      label: "Stain",
    },
    culture: {
      key: "culture",
      label: "Culture",
    },
  },
  cultureConfig: {
    resistant: [],
    sensitive: [],
    reportView: {
      title: "Microbiology",
      tableHeaders: [],
      subTitle: "",
    },
  },
};

export default microBiologyConfig;
