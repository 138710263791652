import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { persistStore, persistReducer } from 'redux-persist';
import { createStore, applyMiddleware, compose } from 'redux';

import rootReducer from './reducers';

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

const middlewares = [];

if (process.env.NODE_ENV === `development`) {
  const { logger } = require(`redux-logger`);

  middlewares.push(logger);
}

const enhancer = composeEnhancers(
  applyMiddleware(...middlewares)
  // other store enhancers if any
);

const persistConfig = {
  key: 'applicationData',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export function configureStore(initialState = {}) {
  const store = createStore(rootReducer, initialState, enhancer);
  // const store = createStore(persistedReducer, initialState, enhancer);
  // const persistor = persistStore(store);
  const persistor = null; //persistStore(store);

  // return { store, persistor };
  return store;
}
