import React from 'react';
import { Layout, Menu } from 'antd';
import { HomeOutlined, FileAddOutlined, SearchOutlined } from '@ant-design/icons';

import { pageConstants } from 'constants/page';

const { Sider } = Layout;

class SideMenu extends React.Component {
  render() {
    return (
      <Sider collapsible collapsed={this.props.sideMenuCollapsed} onCollapse={this.props.onCollapse}>
        <div className="logo" />
        <Menu theme="dark" selectedKeys={this.props.currentPage} mode="inline">
          <Menu.Item
            key={pageConstants.home.key}
            onClick={() => {
              this._selectPage(pageConstants.home.key);
            }}
            icon={<HomeOutlined />}>
            <span>{pageConstants.home.title}</span>
          </Menu.Item>
          <Menu.Item
            key={pageConstants.newReport.key}
            onClick={() => {
              this._selectPage(pageConstants.newReport.key);
            }}
            icon={<FileAddOutlined />}>
            <span>{pageConstants.newReport.title}</span>
          </Menu.Item>
          <Menu.Item
            key={pageConstants.search.key}
            onClick={() => {
              this._selectPage(pageConstants.search.key);
            }}
            icon={<SearchOutlined />}>
            <span>{pageConstants.search.title}</span>
          </Menu.Item>
        </Menu>
      </Sider>
    );
  }

  _selectPage = this.props.selectPage;
}

export default SideMenu;
