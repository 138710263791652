const urineAnalysisConfig = {
  reports: [
    {
      test: "Physical Examination",
      value: "",
      unit: "",
      reference: [""],
      rowClass: "parent",
      show: false,
      key: "physicalExamination",
    },
    {
      test: "Colour",
      value: "",
      unit: "",
      reference: [""],
      rowClass: "child",
      key: "colour",
      parentKey: "physicalExamination",
    },
    {
      test: "Transparency",
      value: "",
      unit: "",
      reference: [""],
      rowClass: "child",
      key: "transparency",
      parentKey: "physicalExamination",
    },
    {
      test: "Sp. Gravity",
      value: "",
      unit: "",
      reference: [""],
      rowClass: "child",
      key: "spGravity",
      parentKey: "physicalExamination",
    },
    {
      test: "Blood",
      value: "",
      unit: "",
      reference: [""],
      rowClass: "child",
      key: "blood",
      parentKey: "physicalExamination",
    },
    {
      test: "Chemical Examination",
      value: "",
      unit: "",
      reference: [""],
      rowClass: "parent",
      show: false,
      key: "chemicalExamination",
    },
    {
      test: "Reaction",
      value: "",
      unit: " ",
      reference: [""],
      rowClass: "child",
      key: "reaction",
      parentKey: "chemicalExamination",
    },
    {
      test: "Albumin",
      value: "",
      unit: "",
      reference: [""],
      rowClass: "child",
      key: "albumin",
      parentKey: "chemicalExamination",
    },
    {
      test: "Sugar",
      value: "",
      unit: "",
      reference: [""],
      rowClass: "child",
      key: "sugar",
      parentKey: "chemicalExamination",
    },
    {
      test: "Chyle",
      value: "",
      unit: "",
      reference: [""],
      rowClass: "child",
      key: "chyle",
      parentKey: "chemicalExamination",
    },
    {
      test: "Acetone",
      value: "",
      unit: "",
      reference: [""],
      rowClass: "child",
      key: "acetone",
      parentKey: "chemicalExamination",
    },
    {
      test: "Diacetic Acid (Ketone)",
      value: "",
      unit: "",
      reference: [""],
      rowClass: "child",
      key: "diaceticAcidKetone",
      parentKey: "chemicalExamination",
    },
    {
      test: "Bild Pigment/Salt",
      value: "",
      unit: "",
      reference: [""],
      rowClass: "child",
      key: "bildPigmentSalt",
      parentKey: "chemicalExamination",
    },
    {
      test: "Bilirubin",
      value: "",
      unit: "",
      reference: [""],
      rowClass: "child",
      key: "bilirubin",
      parentKey: "chemicalExamination",
    },
    {
      test: "Urobilinogen",
      value: "",
      unit: "",
      reference: [""],
      rowClass: "child",
      key: "urobilinogen",
      parentKey: "chemicalExamination",
    },
    {
      test: "Other Abnormalities",
      value: "",
      unit: "",
      reference: [""],
      rowClass: "child",
      key: "otherAbnormalities",
      parentKey: "chemicalExamination",
    },
    {
      test: "Microscopic Examincation Organized Deposits",
      value: "",
      unit: "",
      reference: [""],
      rowClass: "parent",
      show: false,
      key: "microscopicExamincationOrganizedDeposits",
    },
    {
      test: "Pus Cell",
      value: "",
      unit: "/HPF",
      reference: [""],
      rowClass: "child",
      key: "pusCell",
      parentKey: "microscopicExamincationOrganizedDeposits",
    },
    {
      test: "RBC",
      value: "",
      unit: "/HPF",
      reference: [""],
      rowClass: "child",
      key: "rBC",
      parentKey: "microscopicExamincationOrganizedDeposits",
    },
    {
      test: "Epithelical Cells",
      value: "",
      unit: "/HPF",
      reference: [""],
      rowClass: "child",
      key: "epithelicalCells",
      parentKey: "microscopicExamincationOrganizedDeposits",
    },
    {
      test: "Casts",
      value: "",
      unit: "/HPF",
      reference: [""],
      rowClass: "child",
      key: "casts",
      parentKey: "microscopicExamincationOrganizedDeposits",
    },
    {
      test: "Yeast Cells",
      value: "",
      unit: "/HPF",
      reference: [""],
      rowClass: "child",
      key: "yeastCells",
      parentKey: "microscopicExamincationOrganizedDeposits",
    },
    {
      test: "Musus threads",
      value: "",
      unit: "/HPF",
      reference: [""],
      rowClass: "child",
      key: "mususthreads",
      parentKey: "microscopicExamincationOrganizedDeposits",
    },
    {
      test: "Bacteria",
      value: "",
      unit: "/HPF",
      reference: [""],
      rowClass: "child",
      key: "bacteria",
      parentKey: "microscopicExamincationOrganizedDeposits",
    },
    {
      test: "Cylindroid",
      value: "",
      unit: "/HPF",
      reference: [""],
      rowClass: "child",
      key: "cylindroid",
      parentKey: "microscopicExamincationOrganizedDeposits",
    },
    {
      test: "Granular",
      value: "",
      unit: "/HPF",
      reference: [""],
      rowClass: "child",
      key: "granular",
      parentKey: "microscopicExamincationOrganizedDeposits",
    },
    {
      test: "Hyaline",
      value: "",
      unit: "/HPF",
      reference: [""],
      rowClass: "child",
      key: "hyaline",
      parentKey: "microscopicExamincationOrganizedDeposits",
    },
    {
      test: "Waxy",
      value: "",
      unit: "/HPF",
      reference: [""],
      rowClass: "child",
      key: "waxy",
      parentKey: "microscopicExamincationOrganizedDeposits",
    },
    {
      test: "Fatty",
      value: "",
      unit: "/HPF",
      reference: [""],
      rowClass: "child",
      key: "fatty",
      parentKey: "microscopicExamincationOrganizedDeposits",
    },
    {
      test: "Spermatozoa",
      value: "",
      unit: "/HPF",
      reference: [""],
      rowClass: "child",
      key: "spermatozoa",
      parentKey: "microscopicExamincationOrganizedDeposits",
    },
    {
      test: "RBC Cast",
      value: "",
      unit: "/HPF",
      reference: [""],
      rowClass: "child",
      key: "rBCCast",
      parentKey: "microscopicExamincationOrganizedDeposits",
    },
    {
      test: "Tubule cell",
      value: "",
      unit: "/HPF",
      reference: [""],
      rowClass: "child",
      key: "tubulecell",
      parentKey: "microscopicExamincationOrganizedDeposits",
    },
    {
      test: "Oval fat body",
      value: "",
      unit: "/HPF",
      reference: [""],
      rowClass: "child",
      key: "ovalfatbody",
      parentKey: "microscopicExamincationOrganizedDeposits",
    },
    {
      test: "Tubule cell full of protein",
      value: "",
      unit: "/HPF",
      reference: [""],
      rowClass: "child",
      key: "tubulecellfullofprotein",
      parentKey: "microscopicExamincationOrganizedDeposits",
    },
    {
      test: "Granular cast",
      value: "",
      unit: "/HPF",
      reference: [""],
      rowClass: "child",
      key: "granularcast",
      parentKey: "microscopicExamincationOrganizedDeposits",
    },
    {
      test: "Hyaline cast",
      value: "",
      unit: "/HPF",
      reference: [""],
      rowClass: "child",
      key: "hyalinecast",
      parentKey: "microscopicExamincationOrganizedDeposits",
    },
    {
      test: "Waxy cast",
      value: "",
      unit: "/HPF",
      reference: [""],
      rowClass: "child",
      key: "waxycast",
      parentKey: "microscopicExamincationOrganizedDeposits",
    },
    {
      test: "Fatty cast",
      value: "",
      unit: "/HPF",
      reference: [""],
      rowClass: "child",
      key: "fattycast",
      parentKey: "microscopicExamincationOrganizedDeposits",
    },
    {
      test: "Broad cast",
      value: "",
      unit: "/HPF",
      reference: [""],
      rowClass: "child",
      key: "broadcast",
      parentKey: "microscopicExamincationOrganizedDeposits",
    },
    {
      test: "WBC",
      value: "",
      unit: "/HPF",
      reference: [""],
      rowClass: "child",
      key: "wBC",
      parentKey: "microscopicExamincationOrganizedDeposits",
    },
    {
      test: "Pus cell cast",
      value: "",
      unit: "/HPF",
      reference: [""],
      rowClass: "child",
      key: "pusCellCast",
      parentKey: "microscopicExamincationOrganizedDeposits",
    },
    {
      test: "Unorganized Deposits",
      value: "",
      unit: "",
      reference: [""],
      rowClass: "parent",
      show: false,
      key: "unorganizedDeposits",
    },
    {
      test: "Uric Acid",
      value: "",
      unit: "/HPF",
      reference: [""],
      rowClass: "child",
      key: "uricAcid",
      parentKey: "unorganizedDeposits",
    },
    {
      test: "Amorphous Urates",
      value: "",
      unit: "/HPF",
      reference: [""],
      rowClass: "child",
      key: "amorphousUrates",
      parentKey: "unorganizedDeposits",
    },
    {
      test: "Amorphous Phosphates",
      value: "",
      unit: "/HPF",
      reference: [""],
      rowClass: "child",
      key: "amorphousPhosphates",
      parentKey: "unorganizedDeposits",
    },
    {
      test: "Calcium Oxalates",
      value: "",
      unit: "/HPF",
      reference: [""],
      rowClass: "child",
      key: "calciumOxalates",
      parentKey: "unorganizedDeposits",
    },
    {
      test: "Cholesterol",
      value: "",
      unit: "/HPF",
      reference: [""],
      rowClass: "child",
      key: "cholesterol",
      parentKey: "unorganizedDeposits",
    },
    {
      test: "Sodium Urate",
      value: "",
      unit: "/HPF",
      reference: [""],
      rowClass: "child",
      key: "sodiumUrate",
      parentKey: "unorganizedDeposits",
    },
    {
      test: "Cystine",
      value: "",
      unit: "/HPF",
      reference: [""],
      rowClass: "child",
      key: "cystine",
      parentKey: "unorganizedDeposits",
    },
    {
      test: "Fat Drops",
      value: "",
      unit: "/HPF",
      reference: [""],
      rowClass: "child",
      key: "fatDrops",
      parentKey: "unorganizedDeposits",
    },
    {
      test: "Leucin Spheres",
      value: "",
      unit: "/HPF",
      reference: [""],
      rowClass: "child",
      key: "leucinSpheres",
      parentKey: "unorganizedDeposits",
    },
    {
      test: "Tyrosine Needles",
      value: "",
      unit: "/HPF",
      reference: [""],
      rowClass: "child",
      key: "tyrosineNeedles",
      parentKey: "unorganizedDeposits",
    },
    {
      test: "Calcium Carbonate",
      value: "",
      unit: "/HPF",
      reference: [""],
      rowClass: "child",
      key: "calciumCarbonate",
      parentKey: "unorganizedDeposits",
    },
    {
      test: "Triple Phosphate",
      value: "",
      unit: "/HPF",
      reference: [""],
      rowClass: "child",
      key: "triplePhosphate",
      parentKey: "unorganizedDeposits",
    },
    {
      test: "Calcium phosphate",
      value: "",
      unit: "/HPF",
      reference: [""],
      rowClass: "child",
      key: "calciumPhosphate",
      parentKey: "unorganizedDeposits",
    },
    {
      test: "Ammonium urate",
      value: "",
      unit: "/HPF",
      reference: [""],
      rowClass: "child",
      key: "ammoniumUrate",
      parentKey: "unorganizedDeposits",
    },
    {
      test: "Quantitative Analysis",
      value: "",
      unit: "",
      reference: [""],
      rowClass: "parent",
      key: "quantitativeAnalysis",
    },
    {
      test: "Pregnancy",
      value: "",
      unit: "",
      reference: [""],
      rowClass: "parent",
      key: "pregnancy",
    },
  ],
  key: "urineAnalysis",
  tabTitle: "Urine Analysis",
  viewConfig: [
    {
      title: "Test name",
      dataIndex: "test",
      width: "25%",
      editable: false,
    },
    {
      title: "Result",
      dataIndex: "value",
      width: "15%",
      editable: true,
    },
    {
      title: "Unit",
      dataIndex: "unit",
      width: "15%",
      editable: false,
    },
  ],
  reportView: {
    title: "Parasitology",
    subTitle: "Specimen: Urine",
    tableHeaders: [
      { label: "Test", className: "test" },
      { label: "Result", className: "input" },
      { label: "", className: "unit" },
    ],
  },
};

export default urineAnalysisConfig;
